import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ICONS from '../bin/ICONS';
import { EnvelopeIcon, ExclamationTriangleIcon, InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';

const MODAL= (props)=>{
  const type = props.type || "Info", //Info, Message
		headline = props.headline || "Information", //Info, Alert
		html_information = props.html_information || false,
		information = props.information || "",
		action_close = props.action_close || false,
		action_confirm = props.action_confirm || false,
		open = props.open || false,
		setOpen = props.setOpen || null,
		notifyConfirm = props.notifyConfirm || null,
		confirmText = props.confirmText || "Confirm";
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 md:items-end">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
							<button onClick={() => setOpen(false)} className='absolute block right-3 top-3 cursor-pointer'>
								<XMarkIcon className="h-6 w-6 text-rose-500" />
							</button>
							<div className="sm:flex sm:items-start">
                { (type==='Alert') ?
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  :
                  <></>
                }
                { (type==='Info') ?
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  :
                  <></>
                }
                { (type==='Message') ?
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-amber-100 sm:mx-0 sm:h-10 sm:w-10">
                    <EnvelopeIcon className="h-6 w-6 text-amber-600" aria-hidden="true" />
                  </div>
                  :
                  <></>
                }
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full" ref={cancelButtonRef}>
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
										{headline}
                  </Dialog.Title>
                  <div className="mt-2">
                    { (html_information) ?
                      <p className="text-sm text-gray-500 text-justify" dangerouslySetInnerHTML={{ __html:information }}></p>
                      :
                      <p className="text-sm text-gray-500 text-justify">{ information }</p>
                    }
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                { (action_close) ? 
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)} >ok</button>
                  :
                  <></>
								}
								{(action_confirm) ?
									<div className='w-full flex flex-row gap-2 justify-end'>
										<button
											type="button"
											className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
											onClick={() => { setOpen(false); notifyConfirm(); }} >{ confirmText }</button>
										<button
											type="button"
											className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
											onClick={() => setOpen(false)}
											ref={cancelButtonRef} >Cancel</button>
									</div>
								: <></>}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
};
export default MODAL;