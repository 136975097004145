import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import abbrInt from '../bin/abbrInt';

const MAIN = ({ timing, candles }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!candles) return;

    const chart = echarts.init(chartRef.current);

		const formatTimestring = (time) => {
			if (!time || time.indexOf(':') === -1) { return time; }
			time = time.split(':');
			return `${time[0]}:${time[1]}`;
		};

    const candleData = candles.map(candle => ({
			value: [candle.Open, candle.Close, candle.Low, candle.High, candle.Volume, candle.StockNotional],
			notional: candle.StockNotional,
			time: timing && timing==='minute' ? formatTimestring(candle.TimeString) : candle.DateString,
    }));

		const maxVolume = Math.max(...candles.map(candle => candle.Volume));

    const volumeData = candles.map(candle => ({
      value: candle.Volume * 0.30,
      date: candle.DateString,
      itemStyle: {
        color: candle.Close >= candle.Open ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)',
      },
    }));

    const allPrices = [...candleData.map(item => item.value[1])];
    const yAxisMin = Math.min(...allPrices);
    const yAxisMax = Math.max(...allPrices);

		//define the first timestamp and the last timestamp for pre and post shading
		let firstPreMarketTimestamp = null;
		let lastPreMarketTimestamp = null;
		let firstPostMarketTimestamp = null;
		let lastPostMarketTimestamp = null;
		for (let candle of candles) {
			//the first PRE candle should be our start
			if (firstPreMarketTimestamp === null && candle.Market === 'PRE') {
				firstPreMarketTimestamp = formatTimestring(candle.TimeString);
			}
			//the first OPEN candle should be the end of the PRE market section
			if (lastPreMarketTimestamp===null && candle.Market === 'OPEN') {
				lastPreMarketTimestamp = formatTimestring(candle.TimeString);
			}
			//the first POST candle should be our start
			if (firstPostMarketTimestamp === null && candle.Market === 'POST') {
				firstPostMarketTimestamp = formatTimestring(candle.TimeString);
			}
			//always declare the latest POST candle as the last until the end
			if (candle.Market === 'POST') {
				lastPostMarketTimestamp = formatTimestring(candle.TimeString);
			}
		}
		if (!firstPreMarketTimestamp) { firstPreMarketTimestamp = '04:00'; }
		if (!lastPreMarketTimestamp) { lastPreMarketTimestamp = '09:30'; }
		if (!firstPostMarketTimestamp) { firstPostMarketTimestamp = '16:00'; }
		if (!lastPostMarketTimestamp) { lastPostMarketTimestamp = '20:00'; }

    const option = {
			textStyle: {
				color: 'white',
			},
			grid: {
				left: '0',
				right: '0',
				top: '0',
				bottom: '0',
				height: '100%',
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
				},
				backgroundColor: 'rgba(0, 0, 0, 0.80)',
				position: function (point, params, dom, rect, size) {
					const chartWidth = size.viewSize[0];
					const tooltipWidth = size.contentSize[0];
					const x = point[0];
		
					if (x < chartWidth / 2) {
						return [chartWidth - tooltipWidth, 0];
					} else {
						return [0, 0];
					}
				},
				formatter: function (params) {
					const time = params[0].axisValue;
					const priceData = params.find(param => param.seriesName === 'Price')?.data;
					const open = priceData && !isNaN(priceData[1]) ? priceData[1].toLocaleString() : '-';
					const high = priceData && !isNaN(priceData[4]) ? priceData[4].toLocaleString() : '-';
					const low = priceData && !isNaN(priceData[3]) ? priceData[3].toLocaleString() : '-';
					const close = priceData && !isNaN(priceData[2]) ? priceData[2].toLocaleString() : '-';
					const volume = priceData && !isNaN(priceData[5]) ? priceData[5].toLocaleString() : '-';
					const notional = priceData && !isNaN(priceData[6]) ? `$${abbrInt(priceData[6])}` : '-';
		
					return `
						<div style="padding: 10px; text-align: left">
							<span style="color:white;">● Time: ${time}</span><br/>
							<span style="color:white;">● Open: ${open}</span><br/>
							<span style="color:white;">● High: ${high}</span><br/>
							<span style="color:white;">● Low: ${low}</span><br/>
							<span style="color:white;">● Close: ${close}</span><br/>
							<span style="color:white;">● Volume: ${volume}</span><br/>
							<span style="color:white;">● Notional: ${notional}</span><br/>
						</div>
					`;
				},
			},
			xAxis: {
				type: 'category',
				data: candleData.map(item => item.time),
				boundaryGap: true,
				axisLine: {
					onZero: false,
					lineStyle: {
						color: 'black',
					},
				},
				splitLine: { show: false },
				min: 'dataMin',
				max: 'dataMax',
			},
			yAxis: [
				{
					type: 'value',
					name: 'Price',
					position: 'right',
					min: yAxisMin,
					max: yAxisMax,
					axisLabel: {
						color: 'white',
					},
					axisLine: {
						onZero: false,
						lineStyle: {
							color: 'black',
						},
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.2)',
						},
					},
					interval: 5,
					axisPointer: {
						label: {
							formatter: (value) => `$${abbrInt(value.value)}`,
						},
					},

				},
				{
					type: 'value',
					name: 'Notional',
					position: 'left',
					axisPointer: {
						label: {
							color: 'white',
							formatter: (value) => `$${abbrInt(value.value)}`,
						},
					},
					axisLine: {
						lineStyle: {
							color: 'rgba(87, 210, 238, 0.5)',
						},
					},
					splitLine: { show: false },
				},,
				{
					type: 'value',
					name: 'Volume',
					min: 0,
					max: maxVolume,
					axisLabel: {
						show: false,
					},
					axisLine: {
						show: false,
					},
					splitLine: { show: false },
					axisPointer: {
						show: false,
					},
				},
			],
			dataZoom: [
				{
					type: 'inside',
					start: 0,
					end: 100,
				},
				{
					show: false,
					type: 'slider',
					bottom: '0%',
					start: 0,
					end: 100,
				},
			],
			series: [
				{
					type: 'candlestick',
					name: 'Price',
					data: candleData.map(item => item.value),
					itemStyle: {
						color: 'green',
						color0: 'red',
						borderColor: 'green',
						borderColor0: 'red',
					},
				},
				{
					type: 'line',
					name: 'Notional',
					yAxisIndex: 1,
					data: candleData.map(item => item.notional),
					lineStyle: {
						width: 1,
						color: 'rgb(65, 139, 192)'
					},
					showSymbol: false,
				},
				{
					type: 'bar',
					name: 'Volume',
					xAxisIndex: 0,
					yAxisIndex: 1,
					data: volumeData.map(item => item.value),
					barWidth: '60%',
					itemStyle: {
						color: function (params) {
							return candles[params.dataIndex].Close >= candles[params.dataIndex].Open
								? 'rgba(0, 255, 0, 0.3)'
								: 'rgba(255, 0, 0, 0.3)';
						},
					},
				},
			],
		};
		
		chart.setOption(option);

		// Resize handler
    const resizeObserver = new ResizeObserver(() => { chart.resize(); });
    resizeObserver.observe(chartRef.current);

		return () => {
			resizeObserver.disconnect();
      chart.dispose();
    };
  }, [candles]);

  return <div ref={chartRef} style={{ width: '100%', height: '500px' }} />;
};

export default MAIN;
