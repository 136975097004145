import React from 'react';
import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import abbrInt from "../bin/abbrInt";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import { XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

const MODAL= (props)=>{
	const { token, open, setOpen, ticker, totalVolume, exchangeVolume } = props,
		[sortConfig, setSortConfig] = useState('Shares'),
		cancelButtonRef = useRef(null);
	
		const sortedData = Object.entries(exchangeVolume).sort((a, b) => {
			if (!sortConfig) return 0;
			const aValue = a[1][sortConfig];
			const bValue = b[1][sortConfig];
			if (aValue < bValue) { return 1; }
			if (aValue > bValue) { return -1; }
			return 0;
		});
  
	function calculatePercentageOfTotalVolume(totalVolume, shares) {
		if (!totalVolume || isNaN(totalVolume) || !shares || isNaN(shares)) { return ''; }
		let percentage = truncateToTwoDigits((shares / totalVolume) * 100);
		return percentage;
	}

	function renderSortIcon (key) {
    if (sortConfig === key) {
      return <ChevronDownIcon className={`text-sky-800 w-2 h-2 inline-block ml-1`} />;
    }
    return null;
	}
	
  return (
    <Transition.Root show={open} as="div">
      <Dialog as="div" className="fixed inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex justify-center items-center min-h-screen text-center pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full sm:p-6 min-w-[350px]`}>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500" onClick={() => setOpen(false)} >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
									<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 flex items-center justify-center">
										<span className='text-sm bg-amber-300 text-amber-700 px-2 py-0.5 rounded mx-1'>{ticker}</span> Exchange Volume
									</Dialog.Title>
									<div className="mt-2">

										<div className="p-0">
											<div className="overflow-x-auto">
												<table className="min-w-full table-auto border border-gray-300 text-xxxs">
													<thead>
														<tr className="bg-gray-200">
															<th className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300">Exchange</th>
															<th className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300">Percentage</th>
															<th className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300 cursor-pointer" onClick={() => setSortConfig('Shares')}>Shares{renderSortIcon('Shares')}</th>
															<th className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300 cursor-pointer" onClick={() => setSortConfig('Notional')}>Notional{renderSortIcon('Notional')}</th>
															<th className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300 cursor-pointer" onClick={() => setSortConfig('Trades')}>Trades{renderSortIcon('Trades')}</th>
														</tr>
													</thead>
													<tbody>
														{sortedData.map(([exchange, stats]) => (
															<tr key={exchange} className="hover:bg-gray-100">
																<td className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300 text-center">{exchange}</td>
																<td className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300 text-center">{calculatePercentageOfTotalVolume(totalVolume, stats.Shares)}%</td>
																<td className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300 text-center">{stats.Shares.toLocaleString()}</td>
																<td className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300 text-center">${abbrInt(stats.Notional)}</td>
																<td className="sm:px-4 px-0 sm:py-2 py-1 border border-gray-300 text-center">{stats.Trades.toLocaleString()}</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
                    
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
												
  )
};
export default MODAL;