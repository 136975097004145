import { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';
// import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Menu, Transition } from '@headlessui/react'
import { loadStripe } from '@stripe/stripe-js';
import { MinusIcon } from '@heroicons/react/20/solid';
import Headers from "../bin/Headers";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import classNames from "../bin/classNames";
import setMetaTags from "../bin/setMetaTags";
import config from '../config.json';
import RANKINGS from "../components/RANKINGS";
import { ChevronDownIcon, } from "@heroicons/react/24/solid";

const Main = () => {
	const token = useSelector(state => state.token),
		[delayedDataDateString, setDelayedDataDateString] = useState(null);
	
	const rankingslist = [
		{ name: 'Largest Stock Trades', href: '/rankings-highest-stock-trades' },
		{ name: 'Largest Stock Trades - Non Auction', href: '/rankings-highest-stock-trades-non-auction' },
		{ name: 'Largest Binary Option Trades', href: '/rankings-highest-binary-option-trades' },
		{ name: 'Highest Stock Capital', href: '/rankings-highest-capital-stock' },
		{ name: 'Highest Option Premium', href: '/rankings-highest-capital-option-premium' },
		{ name: 'Highest Option Binary Premium', href: '/rankings-highest-capital-option-binary-premium' },
		{ name: 'Highest Stock Volume', href: '/rankings-highest-stock-volume' },
		{ name: 'Highest Option Volume', href: '/rankings-highest-option-volume' },
		{ name: 'Highest Stock Oddlot Turnover', href: '/rankings-highest-stock-oddlot-turnover' },
		{ name: 'Highest Option Turnover', href: '/rankings-highest-option-terminal' },
		{ name: 'Highest Percent Gain', href: '/rankings-highest-percent-gain' },
		{ name: 'Highest Percent Loss', href: '/rankings-highest-percent-loss' },
		{ name: 'Highest Market Cap Gain', href: '/rankings-highest-market-cap-gain' },
		{ name: 'Highest Market Cap Loss', href: '/rankings-highest-market-cap-loss' },
	];
	
	
	useEffect(() => {
		setMetaTags({ title: 'Largest Stock Trades' });

		return () => {
			setMetaTags({ title: 'Red Striped Tie' });
		}
	}, []);

  return (
    <div className="bg-gray-900 py-8 sm:py-12">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
				<h1 className="grid w-full gap-2 text-2xl font-bold text-white sm:grid-cols-[1fr_auto]">
					<span className='self-center'>Largest Stock Trades</span>
					{delayedDataDateString ? <Link to="/pricing" className='self-center justify-self-end text-xxs text-red-200 text-center rounded border border-red-500 bg-rose-950 px-3 py-0'>Trade data from { delayedDataDateString }</Link> : <></>}
				</h1>
				<hr className='my-2' />
				<RANKINGS view={'rankings'} subview={'trade'} study={'stock'} setDelayedDataDateString={setDelayedDataDateString} />
			</div>
    </div>
  );
}
export default Main;