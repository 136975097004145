import Chart from 'chart.js/auto';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import config from "../config.json";
import LOADING from "../components/LOADING";
import decodeJwt from "../bin/decodeJwt";
import unauthorizedLevel from "../bin/unauthorizedLevel";
import authorizedLevel from "../bin/authorizedLevel";
import Headers from "../bin/Headers";
import abbrInt from "../bin/abbrInt";
import dontRerender from "../bin/dontRerender";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";
import PRICE_LEVELS from "../components/PRICE_LEVELS";
import TRADE_TAPE from "../components/TRADE_TAPE";
import LARGEST_TRADES from "../components/LARGEST_TRADES";
import AVERAGES from "../components/AVERAGES";
import OPEN_INTEREST from "../components/OPEN_INTEREST";
import OPTION_CONTRACT_MODAL from "../components/OPTION_CONTRACT_MODAL";
import OPTION_PREMIUM_FLOW from "../components/OPTION_PREMIUM_FLOW";
import STOCK_NOTIONAL_CHART from "../components/STOCK_NOTIONAL_CHART";
import OPTION_NOTIONAL_CHART from "../components/OPTION_NOTIONAL_CHART";
// import LIQUIDITY from "../components/LIQUIDITY";
import ORDER_BOOK from "../components/ORDER_BOOK";
import compileTradeDetailForModal from "../bin/compileTradeDetailForModal";
import ICONS from '../bin/ICONS';
// import '../styles/OPTION_OPEN_INTEREST.css';
import { ReceiptPercentIcon, CalendarDaysIcon, CurrencyDollarIcon, FireIcon, TrophyIcon, LinkIcon, ScaleIcon, SparklesIcon, BanknotesIcon, Square3Stack3DIcon, ListBulletIcon, PresentationChartLineIcon, CheckIcon, BarsArrowDownIcon, BookOpenIcon } from "@heroicons/react/24/solid";

const MAIN = (props) => {
	// let tickersWithOrderBook = ["GME", "SPY", "QQQ", "IWM", "TSLA", "NVDA"];
	// let tickersWithLiquidity = ["GME", "SPY", "QQQ", "IWM"];
	let ticker = props.ticker,
		token = props.token,
		focusedTab = props.focusedTab,
		orderBook = props.orderBook,
		optionStrikeVolume = props.optionStrikeVolume,
		decodedToken = decodeJwt(token),
		optionSnapshot = props.optionSnapshot,
		optionExpirations = props.optionExpirations,
		optionVolume = props.optionVolume,
		optionDetails = props.optionDetails,
		optionContracts = props.optionContracts,
		lastTrade = props.lastTrade,
		topTrades = props.topTrades,
		bid = props.bid,
		ask = props.ask,
		high = props.high,
		low = props.low,
		range = props.range,
		volume = props.volume,
		latestOptionTrades = props.latestOptionTrades,
		latestStockTrades = props.latestStockTrades,
		notableTrades = props.notableTrades,
		topStockTradesByValue = props.topStockTradesByValue,
		topOptionTradeByValue = props.topOptionTradeByValue,
		topOptionBinaryTradeByValue = props.topOptionBinaryTradeByValue,
		topOptionTradeBySize = props.topOptionTradeBySize,
		showStockNotionalChart = props.showStockNotionalChart,
		showOptionNotionalChart = props.showOptionNotionalChart,
		setLoadStockNotionalChart = props.setLoadStockNotionalChart ? props.setLoadStockNotionalChart : ()=>{},
		setLoadOptionNotionalChart = props.setLoadOptionNotionalChart ? props.setLoadOptionNotionalChart : ()=>{},
		[subtleIndicator, setSubtleIndicator] = useState('green'),
		[selectedExpirationDate, setSelectedExpirationDate] = useState(null),
		[selectedLargestTradeType, setSelectedLargestType] = useState('stock trades'),
		[selectableDates, setSelectableExpirationDates] = useState([]),
		[selectedSnapshotDate, setSelectedSnapshotDate] = useState(null),
		[selectableSnapshotDates, setSelectableSnapshotDates] = useState([]),
		[optionChains, setOptionChains] = useState({}),
		[optionChainTotals, setOptionChainTotals] = useState({}),
		[LoadingValue, setLoadingValue] = useState(true),
		[openInterestMultiple, setOpenInterestMultiple] = useState(null),
		[view, setView] = useState('largest'),
		[contractView, setContractView] = useState('volume'),
		strikeTable = useRef(null),
		[showTickerDetail, updateShowTickerDetail] = useState(false),
		[loadingProjectedOIChange, updateLoadingProjectedOIChange] = useState(true),
		[tickerDetail, updateTickerDetail] = useState(null),
		[openInterestInfo, updateOpenInterestInfo] = useState(null),
		[orderBookUnavailable, updateOrderBookUnavailable] = useState(true),
		[liquidityUnavailable, updateLiquidityUnavailable] = useState(true),
		initialOptionChains = useRef({}),
		initialScrollToPrice = useRef(false),
		initialOptionChainTotals = useRef({});

	const start = async (definedSnapshotDate) => {
		if (!optionContracts) {
			setLoadingValue(false);
			return;
		}
		setLoadingValue(true);
		if (!definedSnapshotDate) {
			//load the initial selectable snapshot dates
			// let _selectableSnapshotDates = await fetch(`https://${token ? `io.redstripedtie.com/ursa` : `api.redstripedtie.com`}/openinterest?ticker=${ticker}&datesonly=true`, { headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result && d.result.length ? (d.result).map(({ IndexDate }) => IndexDate) : null);
			let _selectableSnapshotDates = await fetch(`https://io.redstripedtie.com/ursa/openinterest?ticker=${ticker}&datesonly=true`, { headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result && d.result.length ? (d.result).map(({ IndexDate }) => IndexDate) : null);
			// let _selectableSnapshotDates = await fetch(`${config.api}/openinterest?ticker=${ticker}&datesonly=true`, { headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result && d.result.length ? (d.result).map(({ IndexDate }) => IndexDate) : null);
			if (!_selectableSnapshotDates || !_selectableSnapshotDates.length) { setLoadingValue(false); return; }
			definedSnapshotDate = _selectableSnapshotDates[0];
			setSelectedSnapshotDate(_selectableSnapshotDates[0]);
			setSelectableSnapshotDates(_selectableSnapshotDates);
		}

		//load the snapshot
		// let _snapshop = await fetch(`https://${token ? `io.redstripedtie.com/ursa` : `api.redstripedtie.com`}/openinterest?ticker=${ticker}&date=${definedSnapshotDate}`, { headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result && d.result[0] && d.result[0].Snapshot ? d.result[0].Snapshot : null);
		let _snapshop = await fetch(`https://io.redstripedtie.com/ursa/openinterest?ticker=${ticker}&date=${definedSnapshotDate}`, { headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result && d.result[0] && d.result[0].Snapshot ? d.result[0].Snapshot : null);
		// let _snapshop = await fetch(`${config.api}/openinterest?ticker=${ticker}&date=${definedSnapshotDate}`, { headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result && d.result[0] && d.result[0].Snapshot ? d.result[0].Snapshot : null);
		let _selectedExpirationDate = null;
		let _selectableExpirationDates = {};
		let _optionChains = {};
		let _optionChainTotals = {};
		if (_snapshop && _snapshop.length) {
			for (let entry of _snapshop) {
				if (!_selectableExpirationDates.hasOwnProperty(entry.ExpirationDate)) {
					_selectableExpirationDates[entry.ExpirationDate] = entry.ExpirationDate;
					if (!_selectedExpirationDate) {
						_selectedExpirationDate = entry.ExpirationDate;
					}
				}
				if (!_optionChains.hasOwnProperty(entry.ExpirationDate)) {
					_optionChains[entry.ExpirationDate] = {};
				}
				if (!_optionChainTotals.hasOwnProperty(entry.ExpirationDate)) {
					_optionChainTotals[entry.ExpirationDate] = {
						CALLChange: 0,
						CALLVolume: 0,
						CALLOpenInterest: 0,
						CALLOIValue: 0,
						CALLProjectedChange: 0,
						CALLPremium: 0,
						// CALLBinaryPremium: 0,
						PUTChange: 0,
						PUTVolume: 0,
						PUTOpenInterest: 0,
						PUTOIValue: 0,
						PUTProjectedChange: 0,
						PUTPremium: 0,
						// PUTBinaryPremium: 0,
					};
				}
				if (!_optionChains[entry.ExpirationDate][entry.StrikePrice]) {
					_optionChains[entry.ExpirationDate][entry.StrikePrice] = {
						STRIKE: entry.StrikePrice,
						UNDERLYING: entry.UnderlyingTicker,
						PUT: {
							STRIKE: entry.StrikePrice,
							UNDERLYING: entry.UnderlyingTicker,
							TICKER: entry.Ticker,
							TYPE: entry.Type,
							EXPIRATION: entry.ExpirationDate,
							OpenInterest: 0,
							Last: 0,
							OpenInterestValue: 0,
							Change: 0,
							ProjectedChange: 0,
							Premium: 0,
							// BinaryPremium: 0,
						},
						CALL: {
							STRIKE: entry.StrikePrice,
							UNDERLYING: entry.UnderlyingTicker,
							TICKER: entry.Ticker,
							TYPE: entry.Type,
							EXPIRATION: entry.ExpirationDate,
							OpenInterest: 0,
							Last: 0,
							OpenInterestValue: 0,
							Change: 0,
							ProjectedChange: 0,
							Premium: 0,
							// BinaryPremium: 0,
						},
					};
				}
				_optionChainTotals[entry.ExpirationDate][`${entry.Type}Change`] += entry.Change;
				_optionChainTotals[entry.ExpirationDate][`${entry.Type}OpenInterest`] += entry.OpenInterest;
				_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type].OpenInterest = entry.OpenInterest;
				_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type].Change = entry.Change;
				_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type].TICKER = entry.Ticker;
				_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type].TYPE = entry.Type;
			}
			updateHighlightedStrike();
		}

		// console.log("_optionChainTotals: ", _optionChainTotals);
		
		// if (ticker && _selectedExpirationDate && definedSnapshotDate && decodedToken && decodedToken.level && !unauthorizedLevel(decodedToken.level, 'diamond')) {
		// 	let _projected = await fetch(`${config.api}/projectedoptionopeninterestchange?ticker=${ticker}&expiration=${_selectedExpirationDate}&focuseddate=${definedSnapshotDate}`, { headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result ? d.result : null);
		// 	if (_projected) {
		// 		for (let entry of _projected) {
		// 			if (!_optionChains[entry.ExpirationDateString][entry.OptionStrike] || !_optionChains[entry.ExpirationDateString][entry.OptionStrike][entry.OptionType]) { continue; }
		// 			_optionChains[entry.ExpirationDateString][entry.OptionStrike][entry.OptionType].ProjectedChange = entry.ProjectedOpenInterestChange ? entry.ProjectedOpenInterestChange : 0;
		// 			_optionChainTotals[entry.ExpirationDateString][`${entry.OptionType}ProjectedChange`] += entry.ProjectedOpenInterestChange ? entry.ProjectedOpenInterestChange : 0;
		// 		}
		// 	}
		// 	updateLoadingProjectedOIChange(false);
		// }

		calculateSubtleIndicator(_optionChainTotals, _selectedExpirationDate);
		_selectableExpirationDates = _selectableExpirationDates && Object.keys(_selectableExpirationDates) ? Object.keys(_selectableExpirationDates) : null;
		setSelectedExpirationDate(_selectedExpirationDate);
		setOptionChains(_optionChains);
		initialOptionChains.current = _optionChains;
		setOptionChainTotals(_optionChainTotals);
		initialOptionChainTotals.current = _optionChainTotals;
		setSelectableExpirationDates(_selectableExpirationDates);
		readOptionMetrics(optionSnapshot, optionExpirations);
		setLoadingValue(false);
	};
	const updateHighlightedStrike = () => {
		if (!lastTrade) { return; }
		if (!initialScrollToPrice || initialScrollToPrice.current === true) { return; }
		if (strikeTable.current) {
			const highlightElement = strikeTable.current.querySelector("[data-highlight='true']");
			if (highlightElement) {
				const rect = highlightElement.getBoundingClientRect();
				const containerRect = getElementPositionInContainer(highlightElement, strikeTable.current);
				// Calculate the position of the element relative to the container
				const relativeTop = containerRect.top;
				// Calculate the vertical center position
				const centeredTop = relativeTop - (strikeTable.current.clientHeight / 2) + (rect.height / 2);
				// Scroll to the element
				strikeTable.current.scrollTop = centeredTop;
				initialScrollToPrice.current = true;
			}
		}
		// else { setTimeout(() => updateHighlightedStrike(), 1000); }
		loadOptionChainUI(optionChains, selectedExpirationDate);
	}

	function getElementPositionInContainer(element, container) {
		const elemRect = element.getBoundingClientRect();
		const containerRect = container.getBoundingClientRect();
		const topPosition = elemRect.top - containerRect.top + container.scrollTop;
		const leftPosition = elemRect.left - containerRect.left + container.scrollLeft;
		return { top: topPosition, left: leftPosition };
	}
	
	const calculateSubtleIndicator = (_optionChainTotals, _selectedExpirationDate) => {
		//set subtle indicator
		let higherChange = _optionChainTotals[_selectedExpirationDate].CALLChange > _optionChainTotals[_selectedExpirationDate].PUTChange ? 'CALLS' : (_optionChainTotals[_selectedExpirationDate].CALLChange < _optionChainTotals[_selectedExpirationDate].PUTChange ? 'PUTS' : null);
		let higherOpenInterest = _optionChainTotals[_selectedExpirationDate].CALLOpenInterest > _optionChainTotals[_selectedExpirationDate].PUTOpenInterest ? 'CALLS' : (_optionChainTotals[_selectedExpirationDate].CALLOpenInterest < _optionChainTotals[_selectedExpirationDate].PUTOpenInterest ? 'PUTS' : null);
		let higherOpenInterestMultiple = _optionChainTotals[_selectedExpirationDate].CALLOpenInterest > _optionChainTotals[_selectedExpirationDate].PUTOpenInterest ? (((_optionChainTotals[_selectedExpirationDate].CALLOpenInterest / _optionChainTotals[_selectedExpirationDate].PUTOpenInterest) - 1) * 100) : (_optionChainTotals[_selectedExpirationDate].CALLOpenInterest < _optionChainTotals[_selectedExpirationDate].PUTOpenInterest ? (((_optionChainTotals[_selectedExpirationDate].PUTOpenInterest / _optionChainTotals[_selectedExpirationDate].CALLOpenInterest) - 1) * 100) : null);
		if (higherOpenInterestMultiple === Infinity || higherOpenInterestMultiple === -Infinity) {
			setOpenInterestMultiple(``);
		} else if (higherOpenInterestMultiple) {
			setOpenInterestMultiple(`${truncateToTwoDigits(higherOpenInterestMultiple)}%`);
		}
		// if (higherChange === 'CALLS' && higherOpenInterest === 'CALLS' && higherOpenInterestMultiple >= 100) {
		if (higherChange === 'CALLS' && higherOpenInterest === 'CALLS') {
			setSubtleIndicator('green');
			// } else if (higherChange === 'PUTS' && higherOpenInterest === 'PUTS' && higherOpenInterestMultiple >= 100) {
		} else if (higherChange === 'PUTS' && higherOpenInterest === 'PUTS') {
			setSubtleIndicator('red');
		} else {
			setSubtleIndicator('yellow');
		}
	};
	const loadOpenInterestChart = async () => {
		let points = await fetch(`${config.api}/openinterestshares?ticker=${ticker}`, { headers: Headers(token) }).then(d => d.json()).then(d => d && d.result && d.result.length ? d.result : null);
		if (!points || !points.length) { return; }
		let latestValue = points[0];
		let previousValue = points[1];
		let _openInterestInfo = {
			IndexDate: latestValue.IndexDate,
			SharesRepresentedTotal: latestValue.SharesRepresentedTotal,
			SharesRepresentedByCalls: latestValue.SharesRepresentedByCalls,
			SharesRepresentedByPuts: latestValue.SharesRepresentedByPuts,
			SharesRepresentedTotalChange: (latestValue.SharesRepresentedTotal - previousValue.SharesRepresentedTotal),
			SharesRepresentedByCallsChange: (latestValue.SharesRepresentedByCalls - previousValue.SharesRepresentedByCalls),
			SharesRepresentedByPutsChange: (latestValue.SharesRepresentedByPuts - previousValue.SharesRepresentedByPuts),
		};
		updateOpenInterestInfo(_openInterestInfo);
		points = points.reverse();
		let labels = points.map(({ IndexDate }) => IndexDate);
		let totalSharesRepresented = points.map(({ SharesRepresentedTotal }) => SharesRepresentedTotal);
		let totalSharesRepresentedByCalls = points.map(({ SharesRepresentedByCalls }) => SharesRepresentedByCalls);
		let totalSharesRepresentedByPuts = points.map(({ SharesRepresentedByPuts }) => SharesRepresentedByPuts);

		const chartOptionsFlow = new Chart(document.getElementById('OpenInterestShares'), {
			type: 'line',
			data: {
				labels,
				datasets: [
					{
						label: 'Call Shares',
						data: totalSharesRepresentedByCalls,
						fill: false,
						borderColor: 'rgb(1,133,8)',
						radius: 0,
					},
					{
						label: 'Put Shares',
						data: totalSharesRepresentedByPuts,
						fill: false,
						borderColor: 'rgb(133,1,1)',
						radius: 0,
					},
					{
						label: 'Total Shares',
						data: totalSharesRepresented,
						fill: false,
						borderColor: 'rgb(252,198,0)',
						radius: 0,
					}
				],
			},
			options: {
				responsive: true,
				plugins: {
					tooltip: {
						enabled: true,
						callbacks: {
							label: function (context) {
								let label = context.dataset.label || '';
								if (label) { label += ': '; }
								if (context.parsed.y !== null) {
									label += abbrInt(context.parsed.y, false);
								}
								return label;
							}
						}
					}
				},
				scales: {
					x: {
						ticks: {
							callback: function (value, index, ticks) {
								return labels[index];
							}
						}
					},
					y: {
						ticks: {
							callback: function (value, index, ticks) {
								return abbrInt(value, false, false);
							}
						}
					}
				}
			}
		});
	};
	const readOptionMetrics = (explicit_optionSnapshot, explicit_optionExpirations) => {
		let _optionSnapshot = optionSnapshot;
		let _optionExpirations = optionExpirations;
		if (explicit_optionSnapshot) { _optionSnapshot = explicit_optionSnapshot; }
		if (explicit_optionExpirations) { _optionExpirations = explicit_optionExpirations; }

		if (!_optionSnapshot || !Object.values(_optionSnapshot)) { return; }
		if (!_optionExpirations || !Object.values(_optionExpirations)) { return; }
		let _optionChains = { ...initialOptionChains.current };
		let _optionChainTotals = { ...initialOptionChainTotals.current };

		for (let entry of Object.values(_optionSnapshot)) {
			if (!_optionChains[entry.ExpirationDate]) { continue; }
			if (!_optionChains[entry.ExpirationDate][entry.StrikePrice]) { continue; }
			if (!_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type]) { continue; }
			_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type].Volume = entry.Volume;
			_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type].OpenInterest = entry.OpenInterest;
			_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type].Last = entry.Last;
			_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type].OpenInterestValue = entry.OpenInterestValue;
			_optionChains[entry.ExpirationDate][entry.StrikePrice][entry.Type].Premium = entry.Premium;
			
			//reset the totals
			if (!_optionChainTotals[entry.ExpirationDate]) { continue; }
			_optionChainTotals[entry.ExpirationDate][`${entry.Type}Volume`] = 0;
			_optionChainTotals[entry.ExpirationDate][`${entry.Type}Premium`] = 0;
			_optionChainTotals[entry.ExpirationDate][`${entry.Type}OIValue`] = 0;
		}

		//aggregate the totals
		for (let entry of Object.values(_optionSnapshot)) {
			if (!_optionChainTotals[entry.ExpirationDate]) { continue; }
			_optionChainTotals[entry.ExpirationDate][`${entry.Type}Volume`] += entry.Volume && !isNaN(entry.Volume) ? entry.Volume : 0;
			_optionChainTotals[entry.ExpirationDate][`${entry.Type}Premium`] += entry.Premium && !isNaN(entry.Premium) ? entry.Premium : 0;
			_optionChainTotals[entry.ExpirationDate][`${entry.Type}OIValue`] += entry.OpenInterestValue && !isNaN(entry.OpenInterestValue) ? entry.OpenInterestValue : 0;
		}

		// console.log("_optionChains: ", _optionChains);
		// console.log("_optionChainTotals: ", _optionChainTotals);
		setOptionChains(_optionChains);
		setOptionChainTotals(_optionChainTotals);
	};
	const findClosestNumber = (target, arr) => {
		const numArray = arr.map(Number);
		let closestNum = numArray[0];
		for (let i = 1; i < numArray.length; i++) {
			if (Math.abs(target - closestNum) > Math.abs(target - numArray[i])) {
				closestNum = numArray[i];
			}
		}
		return closestNum;
	};
	const loadOptionChainUI = (_optionChains, _selectedExpirationDate) => {
		if (!_optionChains || !Object.keys(_optionChains) || !Object.keys(_optionChains).length || !_selectedExpirationDate) { return; }
		//Sort the strikes
		const arr = Object.entries(_optionChains[_selectedExpirationDate]).map(([key, value]) => ({ STRIKE: parseFloat(key), ...value }));
		const sortedArr = arr.sort((a, b) => a.STRIKE - b.STRIKE).map(a => a.STRIKE.toString());
		let highestCallProjectedChange = null;
		let highestCallProjectedChangeIndex = null;
		let highestCallChange = null;
		let highestCallChangeIndex = null;
		let highestCallOiValue = null;
		let highestCallOiValueIndex = null;
		let highestPutOiValue = null;
		let highestPutOiValueIndex = null;
		let highestCallPremium = null;
		let highestCallPremiumIndex = null;
		let highestPutPremium = null;
		let highestPutPremiumIndex = null;
		let highestCallVolume = null;
		let highestCallVolumeIndex = null;
		let highestPutProjectedChange = null;
		let highestPutProjectedChangeIndex = null;
		let highestPutChange = null;
		let highestPutChangeIndex = null;
		let highestPutVolume = null;
		let highestPutVolumeIndex = null;
		let closestStrike = null;
		let last = lastTrade ? lastTrade : null;
		if (last && sortedArr && sortedArr.length) {
			closestStrike = findClosestNumber(last, sortedArr);
			closestStrike = closestStrike ? closestStrike.toString() : null;
		}
		for (let strike of sortedArr) {
			let data = _optionChains[selectedExpirationDate][strike];
			if (data.CALL.Change > 0 && (highestCallChange === null || Math.abs(data.CALL.Change) > Math.abs(highestCallChange))) {
				highestCallChange = data.CALL.Change;
				highestCallChangeIndex = strike;
			}
			if ((highestCallProjectedChange === null || Math.abs(data.CALL.ProjectedChange) > Math.abs(highestCallProjectedChange))) {
				highestCallProjectedChange = data.CALL.ProjectedChange;
				highestCallProjectedChangeIndex = strike;
			}
			if (data.PUT.Change > 0 && (highestPutChange === null || Math.abs(data.PUT.Change) > Math.abs(highestPutChange))) {
				highestPutChange = data.PUT.Change;
				highestPutChangeIndex = strike;
			}
			if ((highestCallOiValue === null || Math.abs(data.CALL.OpenInterestValue) > Math.abs(highestCallOiValue))) {
				highestCallOiValue = data.CALL.OpenInterestValue;
				highestCallOiValueIndex = strike;
			}
			if ((highestPutOiValue === null || Math.abs(data.PUT.OpenInterestValue) > Math.abs(highestPutOiValue))) {
				highestPutOiValue = data.PUT.OpenInterestValue;
				highestPutOiValueIndex = strike;
			}
			if ((highestCallPremium === null || Math.abs(data.CALL.Premium) > Math.abs(highestCallPremium))) {
				highestCallPremium = data.CALL.Premium;
				highestCallPremiumIndex = strike;
			}
			if ((highestPutPremium === null || Math.abs(data.PUT.Premium) > Math.abs(highestPutPremium))) {
				highestPutPremium = data.PUT.Premium;
				highestPutPremiumIndex = strike;
			}
			if ((highestPutProjectedChange === null || Math.abs(data.PUT.ProjectedChange) > Math.abs(highestPutProjectedChange))) {
				highestPutProjectedChange = data.PUT.ProjectedChange;
				highestPutProjectedChangeIndex = strike;
			}
			if (data.CALL.Volume > 0 && (highestCallVolume === null || data.CALL.Volume > highestCallVolume)) {
				highestCallVolume = data.CALL.Volume;
				highestCallVolumeIndex = strike;
			}
			if (data.PUT.Volume > 0 && (highestPutVolume === null || data.PUT.Volume > highestPutVolume)) {
				highestPutVolume = data.PUT.Volume;
				highestPutVolumeIndex = strike;
			}
			if (closestStrike && strike === closestStrike) {
				_optionChains[selectedExpirationDate][strike].highlight = true;
			} else {
				_optionChains[selectedExpirationDate][strike].highlight = false;
			}
		}
		if (highestCallChange) {
			_optionChains[selectedExpirationDate][highestCallChangeIndex].highestCallChange = true;
		}
		if (highestCallProjectedChange) {
			_optionChains[selectedExpirationDate][highestCallProjectedChangeIndex].highestCallProjectedChange = true;
		}
		if (highestPutChange) {
			_optionChains[selectedExpirationDate][highestPutChangeIndex].highestPutChange = true;
		}
		if (highestCallOiValue) {
			_optionChains[selectedExpirationDate][highestCallOiValueIndex].highestCallOiValue = true;
		}
		if (highestPutOiValue) {
			_optionChains[selectedExpirationDate][highestPutOiValueIndex].highestPutOiValue = true;
		}
		if (highestCallPremium) {
			_optionChains[selectedExpirationDate][highestCallPremiumIndex].highestCallPremium = true;
		}
		if (highestPutPremium) {
			_optionChains[selectedExpirationDate][highestPutPremiumIndex].highestPutPremium = true;
		}
		if (highestPutProjectedChange) {
			_optionChains[selectedExpirationDate][highestPutProjectedChangeIndex].highestPutProjectedChange = true;
		}
		if (highestCallVolume) {
			_optionChains[selectedExpirationDate][highestCallVolumeIndex].highestCallVolume = true;
		}
		if (highestPutVolume) {
			_optionChains[selectedExpirationDate][highestPutVolumeIndex].highestPutVolume = true;
		}
		return (sortedArr).map((strike, idx) => {
			let entry = _optionChains[selectedExpirationDate][strike];
			let link = entry.CALL.TICKER;
			return <div key={idx} data-highlight={`${entry.highlight ? true : false}`} className={`flex flex-row w-full divide-x divide-gray-800 text-[8px] sm:text-xxs text-gray-300 ${entry.highlight ? 'bg-slate-950' : (idx % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800')}`}>
				{contractView === 'volume' ?
					<span className={`${entry.highestCallVolume ? 'bg-emerald-950' : ''} p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.CALL && entry.CALL.Volume ? (entry.CALL.Volume).toLocaleString() : ''}</span>
						<span className='flex sm:hidden'>{entry.CALL && entry.CALL.Volume ? abbrInt(entry.CALL.Volume, false) : ''}</span>
					</span>
					: <></>}
				{contractView === 'oivalue' ?
					<span className={`${entry.highestCallOiValue ? 'bg-emerald-950' : ''} p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.CALL && entry.CALL.OpenInterestValue ? `$${(entry.CALL.OpenInterestValue).toLocaleString()}` : ''}</span>
						<span className='flex sm:hidden'>{entry.CALL && entry.CALL.OpenInterestValue ? `$${abbrInt(entry.CALL.OpenInterestValue, false)}` : ''}</span>
					</span>
					: <></>}
				{contractView === 'premium' ?
					<span className={`${entry.highestCallPremium ? 'bg-emerald-950' : ''} p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.CALL && entry.CALL.Premium ? `$${(entry.CALL.Premium).toLocaleString()}` : ''}</span>
						<span className='flex sm:hidden'>{entry.CALL && entry.CALL.Premium ? `$${abbrInt(entry.CALL.Premium, false)}` : ''}</span>
					</span>
					: <></>}
				{contractView === 'last' ?
					<span className={`p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.CALL && entry.CALL.Last ? `$${(entry.CALL.Last).toLocaleString()}` : ''}</span>
						<span className='flex sm:hidden'>{entry.CALL && entry.CALL.Last ? `$${abbrInt(entry.CALL.Last, false)}` : ''}</span>
					</span>
					: <></>}
				{contractView === 'projectedoichange' ?
					<span className={`${entry.highestCallProjectedChange ? 'bg-emerald-950' : ''} p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.CALL && entry.CALL.ProjectedChange ? (entry.CALL.ProjectedChange).toLocaleString() : ''}</span>
						<span className='flex sm:hidden'>{entry.CALL && entry.CALL.ProjectedChange ? abbrInt(entry.CALL.ProjectedChange, false) : ''}</span>
					</span>
					: <></>}
				<span className={`${entry.highestCallChange ? (entry.highestCallChange > 0 ? 'bg-emerald-950' : (entry.highestCallChange < 0 ? 'bg-rose-950' : '')) : ''} p-1 flex items-center justify-center font-light tracking-wider w-12 ${entry.CALL && entry.CALL.Change && entry.CALL.Change > 0 ? 'text-emerald-500' : ''}${entry.CALL && entry.CALL.Change && entry.CALL.Change < 0 ? 'text-rose-500' : ''}`}>
					<span className='hidden sm:flex'>{entry.CALL && entry.CALL.Change ? (entry.CALL.Change).toLocaleString() : ''}</span>
					<span className='flex sm:hidden'>{entry.CALL && entry.CALL.Change ? abbrInt(entry.CALL.Change, false) : ''}</span>
				</span>
				<span className={`flex items-center justify-center font-light tracking-wider w-12 sm:w-20`}>
					<button className='p-1 w-full flex items-center justify-center' onClick={() => { updateTickerDetail(entry.CALL); updateShowTickerDetail(true); }}>
						<span className='hidden sm:flex'>{entry.CALL && entry.CALL.OpenInterest ? (entry.CALL.OpenInterest).toLocaleString() : 0}</span>
						<span className='flex sm:hidden'>{entry.CALL && entry.CALL.OpenInterest ? abbrInt(entry.CALL.OpenInterest, false) : 0}</span>
					</button>
				</span>
				<span className={`p-1 flex items-center justify-center font-light tracking-wider flex-grow`}>
					{/* <a href={`/alerts?ticker=${link}&type=option`} className='p-1 w-full flex items-center justify-center'> */}
					<span className='p-1 w-full flex items-center justify-center'>
						{entry.STRIKE ? (entry.STRIKE).toLocaleString() : ''}
						{/* </a> */}
					</span>
				</span>
				<span className={`p-1 flex items-center justify-center font-light tracking-wider w-12 sm:w-20`}>
					<button className='p-1 w-full flex items-center justify-center' onClick={() => { updateTickerDetail(entry.PUT); updateShowTickerDetail(true); }}>
						<span className='hidden sm:flex'>{entry.PUT && entry.PUT.OpenInterest ? (entry.PUT.OpenInterest).toLocaleString() : 0}</span>
						<span className='flex sm:hidden'>{entry.PUT && entry.PUT.OpenInterest ? abbrInt(entry.PUT.OpenInterest, false) : 0}</span>
					</button>
				</span>
				<span className={`${entry.highestPutChange ? (entry.highestPutChange > 0 ? 'bg-emerald-950' : (entry.highestPutChange < 0 ? 'bg-rose-950' : '')) : ''} p-1 flex items-center justify-center font-light tracking-wider w-12 ${entry.PUT && entry.PUT.Change && entry.PUT.Change > 0 ? 'text-emerald-500' : ''}${entry.PUT && entry.PUT.Change && entry.PUT.Change < 0 ? 'text-rose-500' : ''}`}>
					<span className='hidden sm:flex'>{entry.PUT && entry.PUT.Change ? (entry.PUT.Change).toLocaleString() : ''}</span>
					<span className='flex sm:hidden'>{entry.PUT && entry.PUT.Change ? abbrInt(entry.PUT.Change, false) : ''}</span>
				</span>
				{contractView === 'volume' ?
					<span className={`${entry.highestPutVolume ? 'bg-rose-950' : ''} p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.PUT && entry.PUT.Volume ? (entry.PUT.Volume).toLocaleString() : ''}</span>
						<span className='flex sm:hidden'>{entry.PUT && entry.PUT.Volume ? abbrInt(entry.PUT.Volume, false) : ''}</span>
					</span>
					: <></>}
				{contractView === 'oivalue' ?
					<span className={`${entry.highestPutOiValue ? 'bg-rose-950' : ''} p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.PUT && entry.PUT.OpenInterestValue ? `$${(entry.PUT.OpenInterestValue).toLocaleString()}` : ''}</span>
						<span className='flex sm:hidden'>{entry.PUT && entry.PUT.OpenInterestValue ? `$${abbrInt(entry.PUT.OpenInterestValue, false)}` : ''}</span>
					</span>
					: <></>}
				{contractView === 'premium' ?
					<span className={`${entry.highestPutPremium ? 'bg-rose-950' : ''} p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.PUT && entry.PUT.Premium ? `$${(entry.PUT.Premium).toLocaleString()}` : ''}</span>
						<span className='flex sm:hidden'>{entry.PUT && entry.PUT.Premium ? `$${abbrInt(entry.PUT.Premium, false)}` : ''}</span>
					</span>
					: <></>}
				{contractView === 'last' ?
					<span className={`p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.PUT && entry.PUT.Last ? `$${(entry.PUT.Last).toLocaleString()}` : ''}</span>
						<span className='flex sm:hidden'>{entry.PUT && entry.PUT.Last ? `$${abbrInt(entry.PUT.Last, false)}` : ''}</span>
					</span>
					: <></>}
				{contractView === 'projectedoichange' ?
					<span className={`${entry.highestPutProjectedChange ? 'bg-rose-950' : ''} p-1 items-center justify-center font-light tracking-wider w-14 sm:w-20 flex`}>
						<span className='hidden sm:flex'>{entry.PUT && entry.PUT.ProjectedChange ? (entry.PUT.ProjectedChange).toLocaleString() : ''}</span>
						<span className='flex sm:hidden'>{entry.PUT && entry.PUT.ProjectedChange ? abbrInt(entry.PUT.ProjectedChange, false) : ''}</span>
					</span>
					: <></>}
			</div>
		});
	};
	const outputHighestOptionSide = (stats, highestBinaryCalls, highestBinaryPuts) => {
		let highestSide = 'hedge';
		let binaryCallPercent = stats.BinaryCallPremium ? Math.abs(Math.trunc((Number(stats.BinaryCallPremium) / Number(stats.Premium)) * 100)) : 0;
		let binaryPutPercent = stats.BinaryPutPremium ? Math.abs(Math.trunc((Number(stats.BinaryPutPremium) / Number(stats.Premium)) * 100)) : 0;
		let nonBinaryPercent = 100 - binaryCallPercent - binaryPutPercent;
		let nonBinaryTotal = Number(stats.Premium) - (Number(stats.BinaryCallPremium) + Number(stats.BinaryPutPremium));
		if (Number(nonBinaryTotal) > Number(stats.BinaryCallPremium) && Number(nonBinaryTotal) > Number(stats.BinaryPutPremium)) {
			highestSide = 'hedge';
		} else if (Number(stats.BinaryCallPremium) > Number(nonBinaryTotal) && Number(stats.BinaryCallPremium) > Number(stats.BinaryPutPremium)) {
			highestSide = 'calls';
		} else if (Number(stats.BinaryPutPremium) > Number(nonBinaryTotal) && Number(stats.BinaryPutPremium) > Number(stats.BinaryCallPremium)) {
			highestSide = 'puts';
		}

		return <span className='grid grid-cols-6 w-full gap-y-1 text-xxs'>
			<div className="col-span-6 md:col-span-4 h-full w-full text-white gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap">
				<span className={`rounded text-xxs w-[80px] sm:text-xs sm:w-[110px] flex flex-col items-center justify-center text-green-300 ${highestBinaryCalls ? 'bg-emerald-950' : ''}`}><span>Binary Calls</span><span className='text-green-300'>${abbrInt(stats.BinaryCallPremium, false)}</span></span>
				<span className={`text-xxs w-[80px] sm:text-xs sm:w-[110px] flex flex-col items-center justify-center text-sky-300`}><span>Hedging</span><span className='text-sky-300'>${abbrInt(nonBinaryTotal, false)}</span></span>
				<span className={`rounded text-xxs w-[80px] sm:text-xs sm:w-[110px] flex flex-col items-center justify-center text-red-300 ${highestBinaryPuts ? 'bg-rose-950' : ''}`}><span>Binary Puts</span><span className='text-red-300'>${abbrInt(stats.BinaryPutPremium, false)}</span></span>
			</div>
			{/* <div className="col-span-6 md:col-span-4 h-full w-full text-white gap-2 flex flex-row items-center justify-between md:justify-center flex-wrap">
				<span className={`rounded text-xxs w-[80px] sm:w-[110px] flex flex-col items-center justify-center text-gray-400`}><span>Call Volume</span><span className='text-green-300'>{abbrInt(stats.CallVolume, false)}</span></span>
				<span className={`text-xxs w-[80px] sm:w-[110px] flex flex-col items-center justify-center text-gray-400`}><span>Total Volume</span><span className='text-sky-300'>{abbrInt((stats.CallVolume + stats.PutVolume), false)}</span></span>
				<span className={`rounded text-xxs w-[80px] sm:w-[110px] flex flex-col items-center justify-center text-gray-400`}><span>Put Volume</span><span className='text-red-300'>{abbrInt(stats.PutVolume, false)}</span></span>
			</div> */}
			<span className='col-span-6 md:col-span-2 inline-flex justify-between w-full items-center'>
				<span className='flex flex-row justify-between w-full text-xxs sm:mx-1 rounded-lg border'>
					<span style={{ width: `${binaryCallPercent}%` }} className={`bg-emerald-700 flex items-center justify-start rounded-l-lg text-white pl-2 text-[8px]`}>{binaryCallPercent}%</span>
					<span style={{ width: `${nonBinaryPercent}%` }} className={`bg-sky-700 flex items-center justify-center text-white text-[8px]`}>{nonBinaryPercent}%</span>
					<span style={{ width: `${binaryPutPercent}%` }} className={`bg-red-700 flex items-center justify-end rounded-r-lg text-white pr-2 text-[8px]`}>{binaryPutPercent}%</span>
				</span>
			</span>
		</span>;
	};
	const loadOptionValueUI = (optionDetails) => {
		if (!optionDetails || !Object.keys(optionDetails).length) { return; }
		let chainMapByExpiration = optionDetails;
		let highestBinaryCalls = null;
		let highestBinaryPuts = null;
		for (let key of Object.keys(chainMapByExpiration)) {
			if (!highestBinaryCalls || highestBinaryCalls.premium < chainMapByExpiration[key].BinaryCallPremium) { highestBinaryCalls = { expiry: key, premium: chainMapByExpiration[key].BinaryCallPremium }; }
			if (!highestBinaryPuts || highestBinaryPuts.premium < chainMapByExpiration[key].BinaryPutPremium) { highestBinaryPuts = { expiry: key, premium: chainMapByExpiration[key].BinaryPutPremium }; }
		}
		
		// return Object.keys(chainMapByExpiration).map((expiry, idx) => {
		// 	return <li key={idx} className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-1 sm:flex-nowrap">
		// 		<div className='w-full mx-1'>
		// 			<p className="text-sm font-semibold leading-6 text-gray-300">Expiration: <span className='text-sky-500'>{expiry}</span></p>
		// 			<div className="flex items-center">
		// 				{outputHighestOptionSide(chainMapByExpiration[expiry], (highestBinaryCalls && highestBinaryCalls.expiry && highestBinaryCalls.expiry===expiry), (highestBinaryPuts && highestBinaryPuts.expiry && highestBinaryPuts.expiry===expiry))}
		// 			</div>
		// 		</div>
		// 	</li>
		// });

		return (Object.keys(chainMapByExpiration)).map((expiry, idx) => {
			let highestSide = 'hedge';
			let binaryCallPercent = chainMapByExpiration[expiry].BinaryCallPremium ? Math.abs(Math.trunc((Number(chainMapByExpiration[expiry].BinaryCallPremium) / Number(chainMapByExpiration[expiry].Premium)) * 100)) : 0;
			let binaryPutPercent = chainMapByExpiration[expiry].BinaryPutPremium ? Math.abs(Math.trunc((Number(chainMapByExpiration[expiry].BinaryPutPremium) / Number(chainMapByExpiration[expiry].Premium)) * 100)) : 0;
			let nonBinaryPercent = 100 - binaryCallPercent - binaryPutPercent;
			let nonBinaryTotal = Number(chainMapByExpiration[expiry].Premium) - (Number(chainMapByExpiration[expiry].BinaryCallPremium) + Number(chainMapByExpiration[expiry].BinaryPutPremium));
			if (Number(nonBinaryTotal) > Number(chainMapByExpiration[expiry].BinaryCallPremium) && Number(nonBinaryTotal) > Number(chainMapByExpiration[expiry].BinaryPutPremium)) {
				highestSide = 'hedge';
			} else if (Number(chainMapByExpiration[expiry].BinaryCallPremium) > Number(nonBinaryTotal) && Number(chainMapByExpiration[expiry].BinaryCallPremium) > Number(chainMapByExpiration[expiry].BinaryPutPremium)) {
				highestSide = 'calls';
			} else if (Number(chainMapByExpiration[expiry].BinaryPutPremium) > Number(nonBinaryTotal) && Number(chainMapByExpiration[expiry].BinaryPutPremium) > Number(chainMapByExpiration[expiry].BinaryCallPremium)) {
				highestSide = 'puts';
			}

			return <div key={idx} className={`flex flex-row w-full text-xxs sm:text-xs ${idx % 2 === 0 ? '' : 'bg-gray-800'}`}>
				<span className="px-1 py-2 items-start sm:items-center justify-center sm:border sm:border-gray-700 sm:border-t-0 font-medium uppercase tracking-wider w-20 flex flex-col sm:flex-row flex-grow">
					<span className='flex w-2/3'>{expiry}</span>
					<span className='flex flex-row justify-between w-full text-xxs sm:mx-1 rounded-lg'>
						<span style={{ width: `${binaryCallPercent}%` }} className={`bg-emerald-700 flex items-center justify-start rounded-l-lg text-white pl-2 font-light text-[7px] h-2`}><span className='hidden sm:inline-block'>{binaryCallPercent}%</span></span>
						<span style={{ width: `${nonBinaryPercent}%` }} className={`bg-sky-700 flex items-center justify-center text-white font-light text-[7px] h-2`}><span className='hidden sm:inline-block'>{nonBinaryPercent}%</span></span>
						<span style={{ width: `${binaryPutPercent}%` }} className={`bg-red-700 flex items-center justify-end rounded-r-lg text-white pr-2 font-light text-[7px] h-2`}><span className='hidden sm:inline-block'>{binaryPutPercent}%</span></span>
					</span>
				</span>
				<span className={`p-1 flex flex-col items-center justify-center sm:border sm:border-gray-700 sm:border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32 text-green-300 ${highestBinaryCalls && highestBinaryCalls.expiry && highestBinaryCalls.expiry === expiry ? 'bg-emerald-800' : ''}`}>
					<span>${abbrInt(chainMapByExpiration[expiry].BinaryCallPremium, false)}</span>
				</span>
				<span className="p-1 flex flex-col items-center justify-center sm:border sm:border-gray-700 sm:border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32 text-sky-300">
					<span>${abbrInt(nonBinaryTotal, false)}</span>
				</span>
				<span className={`p-1 flex flex-col items-center justify-center sm:border sm:border-gray-700 sm:border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32 text-red-300 ${highestBinaryPuts && highestBinaryPuts.expiry && highestBinaryPuts.expiry === expiry ? 'bg-rose-800' : ''}`}>
					<span>${abbrInt(chainMapByExpiration[expiry].BinaryPutPremium, false)}</span>
				</span>
			</div>;
		});
	};
	
	useEffect(() => {
		// if (!decodedToken || !decodedToken.level || unauthorizedLevel(decodedToken.level, 'diamond')) { return; }
		// if (!tickersWithLiquidity.includes(ticker)) { return; }
		// updateLiquidityUnavailable(false);
		//if diamond level... load straight to the Stikes view
		// let loadStrikesView = authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : 'red'), 'diamond');
		// if (loadStrikesView) {
		// 	setView('value');
		// 	focusedTab.current = 'value';
		// 	setLoadStockNotionalChart(false);
		// 	setLoadOptionNotionalChart(false);
		// 	setSelectedExpirationDate(selectableDates[0]);
		// 	readOptionMetrics();
		// 	return;
		// }
		if (showStockNotionalChart) {
			setView('stocknotional');
			focusedTab.current = 'stocknotional';
			return;
		}
		if (showOptionNotionalChart) {
			setView('optionnotional');
			focusedTab.current = 'optionnotional';
			return;
		}
	}, [ticker, showStockNotionalChart, showOptionNotionalChart]);
	useEffect(() => { if (orderBook) { updateOrderBookUnavailable(false); } }, [orderBook]);
	useEffect(() => { start(); focusedTab.current=view; },[optionContracts]);
  useEffect(()=>{ readOptionMetrics(); },[optionSnapshot, optionExpirations]);
	useLayoutEffect(() => { updateHighlightedStrike(); }, [lastTrade]);
	// useEffect(() => {
	// 	if (showStockNotionalChart) {
	// 		setView('stocknotional');
	// 		focusedTab.current = 'stocknotional';
	// 	}
	// }, [showStockNotionalChart]);
	// useEffect(() => {
	// 	if (showOptionNotionalChart) {
	// 		setView('optionnotional');
	// 		focusedTab.current = 'optionnotional';
	// 	}
	// }, [showOptionNotionalChart]);

	return (
	<div className="rounded-lg flex flex-col justify-start items-start bg-gray-900 border border-solid border-gray-500 w-full lg:w-3/5 flex-grow">
	{/* <div className="rounded-lg flex flex-col justify-start items-start bg-gray-900 border border-solid border-gray-500 w-full xl:w-3/5 flex-grow"> */}
		<div className={`border-b border-b-gray-800 rounded-t-lg text-gray-100 w-full text-center text-semibold flex flex-col justify-center items-start px-1 py-0.5 ${subtleIndicator==='red' ? 'bg-gradient-to-r from-rose-950' : ''}${subtleIndicator==='green' ? 'bg-gradient-to-r from-emerald-950' : ''}${subtleIndicator==='yellow' ? 'bg-gradient-to-r from-yellow-800' : ''}${!subtleIndicator ? 'bg-gray-800' : ''}`}>
			<span className='flex flex-col sm:flex-row items-center sm:items-start justify-between w-full'>
				{view === 'stocknotional' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Capital Flow Chart</span> : <></>}
				{view === 'value' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Option Chain Analysis</span> : <></>}
				{view === 'changed' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Option Chain</span> : <></>}
				{view === 'levels' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Price Levels</span> : <></>}
				{view === 'openinterest' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Shares Represented by Open Interest</span> : <></>}
				{view === 'orderbook' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Order Book</span> : <></>}
				{view === 'trades' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Tape Feed</span> : <></>}
				{view === 'largest' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-between w-full'>
					<span className='flex flex-row flex-grow items-center justify-start sm:justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Largest Trades</span>
					<div className={`flex-row items-end justify-end flex`}>
							{/* <select className="block rounded-md border-0 text-gray-900 text-xs py-0.5 pl-1 pr-7" defaultValue={selectedExpirationDate} onChange={(event) => { setSelectedExpirationDate(event.target.value); if (event.target.value !== '') { calculateSubtleIndicator(optionChainTotals, event.target.value); } }}> */}
							<select className="block rounded-md border-0 text-gray-900 text-xs py-0.5 pl-1 pr-7" defaultValue={selectedLargestTradeType} onChange={(event) => { setSelectedLargestType(event.target.value); }}>
							<option value='stock trades'>stock trades</option>
								{optionContracts ? <option value='binary options'>binary options</option> : <></>}
								{optionContracts ? <option value='valuable options'>valuable options</option> : <></>}
								{optionContracts ? <option value='sizable options'>sizable options</option> : <></>}
								{/* {optionContracts ? <option value='liquidity trades'>liquidity trades</option> : <></>} */}
								{optionContracts ? <option value='notable options'>notable options</option> : <></>}
						</select>
					</div>
				</span> : <></>}
				{view === 'liquidity' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Liquidity</span> : <></>}
				{view === 'averages' ? <span className='py-1 text-sm font-medium px-2 flex-grow text-center flex-row flex items-center justify-center'><span className="mx-1 inline-flex justify-center items-center rounded-full bg-purple-300 px-1.5 py-0 text-xs leading-tight font-medium text-purple-800">{ticker}</span>Averages</span> : <></>}
				{decodedToken && decodedToken.level && !unauthorizedLevel(decodedToken.level, 'diamond') && view !== 'stocknotional' && view !== 'orderbook' && view !== 'value' && view !== 'levels' && view !== 'trades' && view !== 'largest' && !LoadingValue && selectableSnapshotDates && selectableSnapshotDates.length > 0 && selectedSnapshotDate ?
					<div className={`w-[250px] flex-row items-end justify-between gap-4 flex py-1 px-2`}>
						<label className={`text-left block text-sm font-light flex-grow text-gray-300 ${view === 'openinterest' ? 'hidden' : ''}`}>Snapshot</label>
						<select className={`block rounded-md border-0 text-gray-900 text-xs py-0.5 pl-1 pr-7 ${view === 'openinterest' ? 'hidden' : ''}`} defaultValue={selectedSnapshotDate} onChange={(event) => { setSelectedSnapshotDate(event.target.value); start(event.target.value); }}>
							{(selectableSnapshotDates).map((entry, idx) => {
								return <option value={entry} key={idx}>{entry}</option>
							})}
						</select>
					</div>
				: <></>}
				{/* {!unauthorizedLevel(decodedToken.level, 'diamond') && view === 'largest' && !LoadingValue && selectableSnapshotDates && selectableSnapshotDates.length > 0 && selectedSnapshotDate ?
					<div className={`w-[250px] flex-row items-end justify-between gap-4 flex py-1 px-2`}>
						<label className={`text-left block text-sm font-light flex-grow text-gray-300 ${view === 'openinterest' ? 'hidden' : ''}`}>Snapshot</label>
						<select className={`block rounded-md border-0 text-gray-900 text-xs py-0.5 pl-1 pr-7 ${view === 'openinterest' ? 'hidden' : ''}`} defaultValue={selectedSnapshotDate} onChange={(event) => { setSelectedSnapshotDate(event.target.value); start(event.target.value); }}>
							{(selectableSnapshotDates).map((entry, idx) => {
								return <option value={entry} key={idx}>{entry}</option>
							})}
						</select>
					</div>
				: <></>} */}
			</span>
			
			<span className='flex flex-col sm:flex-row items-center sm:items-start justify-between w-full'>
				<span className="inline-flex items-center gap-1">
					{/* <button
						type="button"
						disabled={!optionContracts}
						onClick={() => { setView('openinterest'); loadOpenInterestChart(); setLoadStockNotionalChart(false); setLoadOptionNotionalChart(false); }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border ${view==='openinterest' ? 'border-white text-white bg-emerald-600' : (optionContracts ? 'border-gray-500 text-gray-300 bg-slate-700' : 'border-gray-500/30 text-gray-300/30 bg-slate-700/30')}`}>
						<PresentationChartLineIcon className='w-5 h-4' />
					</button> */}
					{ticker ? <button
						type="button"
						disabled={true}
						onClick={() => { }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border border-gray-500/30 text-gray-300/30 bg-slate-700/30`}>
						<CalendarDaysIcon className='w-5 h-4' />
					</button> : <></>}
					{ticker ? <button
						type="button"
						disabled={liquidityUnavailable}
						onClick={() => { setView('liquidity'); focusedTab.current='liquidity'; setLoadStockNotionalChart(false); setLoadOptionNotionalChart(false); }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border ${view === 'liquidity' ? 'border-white text-white bg-emerald-600' : (!liquidityUnavailable ? 'border-gray-500 text-gray-300 bg-slate-700' : 'border-gray-500/30 text-gray-300/30 bg-slate-700/30')}`}>
						<ScaleIcon className='w-5 h-4' />
					</button> : <></>}
					{/*ticker ? <button
						type="button"
						disabled={orderBookUnavailable}
						onClick={() => { setView('orderbook'); focusedTab.current='orderbook'; setLoadStockNotionalChart(false); setLoadOptionNotionalChart(false); }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border ${view === 'orderbook' ? 'border-white text-white bg-emerald-600' : (!orderBookUnavailable ? 'border-gray-500 text-gray-300 bg-slate-700' : 'border-gray-500/30 text-gray-300/30 bg-slate-700/30')}`}>
						<BookOpenIcon className='w-5 h-4' />
					</button> : <></>*/}
					<button
						type="button"
						disabled={!optionContracts}
						onClick={() => { setView('value'); focusedTab.current='value'; setLoadStockNotionalChart(false); setLoadOptionNotionalChart(false); setSelectedExpirationDate(selectableDates[0]); readOptionMetrics(); }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border ${view==='value' ? 'border-white text-white bg-emerald-600' : (optionContracts ? 'border-gray-500 text-gray-300 bg-slate-700' : 'border-gray-500/30 text-gray-300/30 bg-slate-700/30')}`}>
						<CurrencyDollarIcon className='w-5 h-4' />
					</button>
					<button
						type="button"
						disabled={!token || !optionContracts}
						onClick={() => { setView('changed'); focusedTab.current='changed'; setLoadStockNotionalChart(false); setLoadOptionNotionalChart(false); setSelectedExpirationDate(selectableDates[0]); readOptionMetrics(); }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border ${view==='changed' ? 'border-white text-white bg-emerald-600' : (optionContracts && token ? 'border-gray-500 text-gray-300 bg-slate-700' : 'border-gray-500/30 text-gray-300/30 bg-slate-700/30')}`}>
						<FireIcon className='w-5 h-4' />
					</button>
					{/* {decodedToken && decodedToken.level && !unauthorizedLevel(decodedToken.level, 'diamond') ? <button */}
					<button
						type="button"
						disabled={!token}
							onClick={() => { setView('levels'); focusedTab.current='levels'; setLoadStockNotionalChart(false); setLoadOptionNotionalChart(false); }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border ${view==='levels' ? 'border-white text-white bg-emerald-600' : (token ? 'border-gray-500 text-gray-300 bg-slate-700' : 'border-gray-500/30 text-gray-300/30 bg-slate-700/30')}`}>
						<ListBulletIcon className='w-5 h-4' />
					</button>
					
					<button
						type="button"
						onClick={() => { setView('largest'); focusedTab.current='largest'; setLoadStockNotionalChart(false); setLoadOptionNotionalChart(false); setSelectedExpirationDate(''); }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border ${view==='largest' ? 'border-white text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
						<TrophyIcon className='w-5 h-4' />
					</button>
					
					{/* <button
						type="button"
						disabled={!token}
						onClick={() => { setView('trades'); focusedTab.current='trades'; setLoadStockNotionalChart(false); setLoadOptionNotionalChart(false); setSelectedExpirationDate(''); }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border ${view==='trades' && token ? 'border-white text-white bg-emerald-600' : (token ? 'border-gray-500 text-gray-300 bg-slate-700' : 'border-gray-500/30 text-gray-300/30 bg-slate-700/30')}`}>
						<BarsArrowDownIcon className='w-5 h-4' />
					</button> */}
					
					{decodedToken && decodedToken.level && !unauthorizedLevel(decodedToken.level, 'diamond') ? <button
						type="button"
						onClick={() => { setView('averages'); focusedTab.current='averages'; setLoadStockNotionalChart(false); setLoadOptionNotionalChart(false); }}
						className={`rounded px-2 py-0.5 text-xs font-semibold border ${view==='averages' ? 'border-white text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
						<ReceiptPercentIcon className='w-5 h-4' />
					</button> : <></>}
				</span>
				
				{view !== 'stocknotional' && view !== 'orderbook' && view !== 'value' && view !== 'levels' && view !== 'largest' && view !== 'liquidity' && view !== 'trades' && view !== 'averages' && !LoadingValue && selectableDates && selectableDates.length > 0 ?
					<div className={`order-first sm:order-none w-[250px] flex-row items-end justify-between sm:justify-end gap-4 flex py-1 px-2 ${view==='openinterest' ? 'hidden' : ''}`}>
						<label className="text-left block text-sm font-light sm:flex-grow text-gray-300">Expiration</label>
							<select className="block rounded-md border-0 text-gray-900 text-xs py-0.5 pl-1 pr-7" defaultValue={selectedExpirationDate} onChange={(event) => { setSelectedExpirationDate(event.target.value); if (event.target.value !== '') { calculateSubtleIndicator(optionChainTotals, event.target.value); } }}>
							<option value=''>-</option>
							{(selectableDates).map((entry, idx) => { return <option value={entry} key={idx}>{entry}</option> })}
						</select>
					</div>
					:
					<></>
				}
				
			</span>
		</div>

		{view === 'changed' ?
			<div className="w-full h-full">
			
				{LoadingValue === true && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300"><LOADING color="text-green-500" /></div>}
				{(!LoadingValue && (!optionChains || !Object.keys(optionChains) || !Object.keys(optionChains).length)) &&
					<div className="h-full flex items-center justify-center py-8 text-center text-gray-300">no options</div>
				}
			

				{!LoadingValue && Object.keys(optionChains) && Object.keys(optionChains).length > 0 ?
					<div className="w-full">
						<div className='flex flex-row gap-1 items-start justify-evenly w-full text-xxs sm:text-xs bg-sky-950 px-2 py-1 mb-0.5 border-t border-b'>
							
							{/* <div className="sm:hidden">
								<select className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
									<option key="Volume" value={"Volume"}>Volume</option>
									<option key="Last" value={"Last"}>Last</option>
									<option key="Premium" value={"Premium"}>Premium</option>
									<option key="Open Interest Value" value={"Open Interest Value"}>Open Interest Value</option>
								</select>
							</div>
							<div className="hidden sm:block">
								<div className="border-b border-gray-200">
									<nav className="-mb-px flex space-x-8">
										<button
											key={"Volume"}
											onClick={() => { setContractView('volume'); }}
											className={`whitespace-nowrap border-b-2 py-4 px-1 text-xs font-medium ${contractView === 'volume' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
											>
											Volume
										</button>
										<button
											key={"Last"}
											onClick={() => { setContractView('last'); }}
											className={`whitespace-nowrap border-b-2 py-4 px-1 text-xs font-medium ${contractView === 'last' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
											>
											Last
										</button>
										<button
											key={"Premium"}
											onClick={() => { setContractView('premium'); }}
											className={`whitespace-nowrap border-b-2 py-4 px-1 text-xs font-medium ${contractView === 'premium' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
											>
											Premium
										</button>
										<button
											key={"Open Interest Value"}
											onClick={() => { setContractView('oivalue'); }}
											className={`whitespace-nowrap border-b-2 py-4 px-1 text-xs font-medium ${contractView === 'oivalue' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
											>
											OI Value
										</button>
									</nav>
								</div>
							</div> */}
								
							<button
								type="button"
								onClick={() => { setContractView('volume'); }}
								className={`rounded px-2 py-0.5 text-xs font-semibold border ${contractView==='volume' ? 'border-white text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
								<span className='flex sm:hidden'>Vol</span>
								<span className='hidden sm:flex'>Volume</span>
							</button>
							<button
								type="button"
								onClick={() => { setContractView('last'); }}
								className={`rounded px-2 py-0.5 text-xs font-semibold border ${contractView==='last' ? 'border-white text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
								Last
							</button>
							<button
								type="button"
								onClick={() => { setContractView('premium'); }}
								className={`rounded px-2 py-0.5 text-xs font-semibold border ${contractView==='premium' ? 'border-white text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
								Premium
							</button>
							<button
								type="button"
								onClick={() => { setContractView('oivalue'); }}
								className={`rounded px-2 py-0.5 text-xs font-semibold border ${contractView==='oivalue' ? 'border-white text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
								OI Value
							</button>
							{decodedToken && decodedToken.level && !unauthorizedLevel(decodedToken.level, 'diamond') ? <button
								type="button"
								onClick={() => { setContractView('projectedoichange'); }}
								className={`rounded flex flex-row gap-2 items-center ${loadingProjectedOIChange ? 'pl-2' : 'px-2'} py-0.5 text-xs font-semibold border ${contractView === 'projectedoichange' ? 'border-white text-white bg-emerald-600' : 'border-gray-500 text-gray-300 bg-slate-700'}`}>
								<span className='flex sm:hidden'>Projected</span>
								<span className='hidden sm:flex'>Projected</span>
							</button> : ''}
							
						</div>
						<div className='flex flex-row w-full text-xxs sm:text-xs'>
							<span className="p-1 items-center justify-center border border-t-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex">
								<span className='flex sm:hidden'>
									{contractView==='volume' ? 'Vol' : ''}
									{contractView==='oivalue' ? 'OI $' : ''}
									{contractView==='premium' ? 'Prem' : ''}
									{contractView==='last' ? 'Last' : ''}
									{contractView==='projectedoichange' ? 'Proj' : ''}
								</span>
								<span className='hidden sm:flex'>
									{contractView==='volume' ? 'Volume' : ''}
									{contractView==='oivalue' ? 'OI Value' : ''}
									{contractView === 'premium' ? 'Premium' : ''}
									{contractView==='last' ? 'Last' : ''}
									{contractView==='projectedoichange' ? 'Projected' : ''}
								</span>
							</span>
							<span className="p-1 flex items-center justify-center border border-t-0 font-medium uppercase tracking-wider w-12">+/-</span>
							<span className="p-1 flex items-center justify-center border border-t-0 font-medium uppercase tracking-wider w-12 sm:w-20">OI</span>
							<span className="p-1 flex items-center justify-center border border-t-0 font-medium uppercase tracking-wider flex-grow">
								<span className='hidden sm:block'>Strike</span>
								<span className='block sm:hidden'>$</span>
							</span>
							<span className="p-1 flex items-center justify-center border border-t-0 font-medium uppercase tracking-wider w-12 sm:w-20">OI</span>
							<span className="p-1 flex items-center justify-center border border-t-0 font-medium uppercase tracking-wider w-12">+/-</span>
							<span className="p-1 items-center justify-center border border-t-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex">
								<span className='flex sm:hidden'>
									{contractView==='volume' ? 'Vol' : ''}
									{contractView==='oivalue' ? 'OI $' : ''}
									{contractView === 'premium' ? 'Prem' : ''}
									{contractView==='last' ? 'Last' : ''}
									{contractView==='projectedoichange' ? 'Proj' : ''}
								</span>
								<span className='hidden sm:flex'>
									{contractView === 'volume' ? 'Volume' : ''}
									{contractView==='oivalue' ? 'OI Value' : ''}
									{contractView === 'premium' ? 'Premium' : ''}
									{contractView==='last' ? 'Last' : ''}
									{contractView==='projectedoichange' ? 'Projected' : ''}
								</span>
							</span>
						</div>
					
						<div ref={strikeTable} className="overflow-x-auto h-full max-h-[400px] xl:max-h-[700px] w-full">
							{loadOptionChainUI(optionChains, selectedExpirationDate)}
						</div>
					
						{!LoadingValue && Object.keys(optionChainTotals) && Object.keys(optionChainTotals).length > 0 && optionChainTotals[selectedExpirationDate] ?
								<div className='flex flex-row w-full text-[8px] sm:text-xs'>
								{contractView==='volume' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex ${optionChainTotals[selectedExpirationDate].CALLVolume > optionChainTotals[selectedExpirationDate].PUTVolume ? 'bg-emerald-800' : ''}`}>
										<span className={`${optionChainTotals[selectedExpirationDate].CALLVolume > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].CALLVolume ? optionChainTotals[selectedExpirationDate].CALLVolume.toLocaleString() : ''}</span>
										<span className={`${optionChainTotals[selectedExpirationDate].CALLVolume > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].CALLVolume ? abbrInt(optionChainTotals[selectedExpirationDate].CALLVolume, false) : ''}</span>
									</span>
								: <></>}
								{contractView==='oivalue' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex ${optionChainTotals[selectedExpirationDate].CALLOIValue > optionChainTotals[selectedExpirationDate].PUTOIValue ? 'bg-emerald-800' : ''}`}>
										<span className={`${optionChainTotals[selectedExpirationDate].CALLOIValue > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].CALLOIValue ? `$${optionChainTotals[selectedExpirationDate].CALLOIValue.toLocaleString()}` : ''}</span>
										<span className={`${optionChainTotals[selectedExpirationDate].CALLOIValue > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].CALLOIValue ? `$${abbrInt(optionChainTotals[selectedExpirationDate].CALLOIValue, false)}` : ''}</span>
									</span>
								: <></>}
								{contractView==='premium' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex ${optionChainTotals[selectedExpirationDate].CALLPremium > optionChainTotals[selectedExpirationDate].PUTPremium ? 'bg-emerald-800' : ''}`}>
										<span className={`${optionChainTotals[selectedExpirationDate].CALLPremium > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].CALLPremium ? `$${optionChainTotals[selectedExpirationDate].CALLPremium.toLocaleString()}` : ''}</span>
										<span className={`${optionChainTotals[selectedExpirationDate].CALLPremium > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].CALLPremium ? `$${abbrInt(optionChainTotals[selectedExpirationDate].CALLPremium, false)}` : ''}</span>
									</span>
								: <></>}
								{contractView==='last' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex`}>
										&nbsp;
									</span>
								: <></>}
								{contractView==='projectedoichange' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex ${optionChainTotals[selectedExpirationDate].CALLProjectedChange > optionChainTotals[selectedExpirationDate].PUTProjectedChange ? 'bg-emerald-800' : ''}`}>
										<span className={`${optionChainTotals[selectedExpirationDate].CALLProjectedChange > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].CALLProjectedChange ? optionChainTotals[selectedExpirationDate].CALLProjectedChange.toLocaleString() : ''}</span>
										<span className={`${optionChainTotals[selectedExpirationDate].CALLProjectedChange > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].CALLProjectedChange ? abbrInt(optionChainTotals[selectedExpirationDate].CALLProjectedChange, false) : ''}</span>
									</span>
								: <></>}
								<span className={`p-1 flex items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-12 ${optionChainTotals[selectedExpirationDate].CALLChange > optionChainTotals[selectedExpirationDate].PUTChange ? 'bg-emerald-800' : ''}`}>
									<span className={`${Math.abs(optionChainTotals[selectedExpirationDate].CALLChange) > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].CALLChange ? optionChainTotals[selectedExpirationDate].CALLChange.toLocaleString() : ''}</span>
									<span className={`${Math.abs(optionChainTotals[selectedExpirationDate].CALLChange) > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].CALLChange ? abbrInt(optionChainTotals[selectedExpirationDate].CALLChange, false) : ''}</span>
								</span>
								<span className={`p-1 flex items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-12 sm:w-20 ${optionChainTotals[selectedExpirationDate].CALLOpenInterest > optionChainTotals[selectedExpirationDate].PUTOpenInterest ? 'bg-emerald-900' : ''}`}>
									<span className={`${optionChainTotals[selectedExpirationDate].CALLOpenInterest > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].CALLOpenInterest ? optionChainTotals[selectedExpirationDate].CALLOpenInterest.toLocaleString() : ''}</span>
									<span className={`${optionChainTotals[selectedExpirationDate].CALLOpenInterest > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].CALLOpenInterest ? abbrInt(optionChainTotals[selectedExpirationDate].CALLOpenInterest, false) : ''}</span>
								</span>
								{/* <span className={`p-1 flex items-center justify-center border border-b-0 font-medium uppercase tracking-wider flex-grow ${optionChainTotals[selectedExpirationDate].CALLOpenInterest > optionChainTotals[selectedExpirationDate].PUTOpenInterest ? 'bg-emerald-900' : ''}${optionChainTotals[selectedExpirationDate].CALLOpenInterest < optionChainTotals[selectedExpirationDate].PUTOpenInterest ? 'bg-rose-900' : ''}`}> */}
								<span className={`p-1 flex items-center justify-center border border-b-0 font-medium uppercase tracking-wider flex-grow`}>
									{/* {openInterestMultiple ? openInterestMultiple : ''} */}
								</span>
								<span className={`p-1 flex items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-12 sm:w-20 ${optionChainTotals[selectedExpirationDate].CALLOpenInterest < optionChainTotals[selectedExpirationDate].PUTOpenInterest ? 'bg-rose-900' : ''}`}>
									<span className={`${optionChainTotals[selectedExpirationDate].PUTOpenInterest > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].PUTOpenInterest ? optionChainTotals[selectedExpirationDate].PUTOpenInterest.toLocaleString() : ''}</span>
									<span className={`${optionChainTotals[selectedExpirationDate].PUTOpenInterest > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].PUTOpenInterest ? abbrInt(optionChainTotals[selectedExpirationDate].PUTOpenInterest, false) : ''}</span>
								</span>
								<span className={`p-1 flex items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-12 ${optionChainTotals[selectedExpirationDate].CALLChange < optionChainTotals[selectedExpirationDate].PUTChange ? 'bg-rose-800' : ''}`}>
									<span className={`${Math.abs(optionChainTotals[selectedExpirationDate].PUTChange) > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].PUTChange ? optionChainTotals[selectedExpirationDate].PUTChange.toLocaleString() : ''}</span>
									<span className={`${Math.abs(optionChainTotals[selectedExpirationDate].PUTChange) > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].PUTChange ? abbrInt(optionChainTotals[selectedExpirationDate].PUTChange, false) : ''}</span>
								</span>
								{contractView==='volume' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex ${optionChainTotals[selectedExpirationDate].CALLVolume < optionChainTotals[selectedExpirationDate].PUTVolume ? 'bg-rose-800' : ''}`}>
										<span className={`${optionChainTotals[selectedExpirationDate].PUTVolume > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].PUTVolume ? optionChainTotals[selectedExpirationDate].PUTVolume.toLocaleString() : ''}</span>
										<span className={`${optionChainTotals[selectedExpirationDate].PUTVolume > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].PUTVolume ? abbrInt(optionChainTotals[selectedExpirationDate].PUTVolume, false) : ''}</span>
									</span>
								: <></>}
								{contractView==='oivalue' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex ${optionChainTotals[selectedExpirationDate].CALLOIValue < optionChainTotals[selectedExpirationDate].PUTOIValue ? 'bg-rose-800' : ''}`}>
										<span className={`${optionChainTotals[selectedExpirationDate].PUTOIValue > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].PUTOIValue ? `$${optionChainTotals[selectedExpirationDate].PUTOIValue.toLocaleString()}` : ''}</span>
										<span className={`${optionChainTotals[selectedExpirationDate].PUTOIValue > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].PUTOIValue ? `$${abbrInt(optionChainTotals[selectedExpirationDate].PUTOIValue, false)}` : ''}</span>
									</span>
								: <></>}
								{contractView==='premium' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex ${optionChainTotals[selectedExpirationDate].CALLPremium < optionChainTotals[selectedExpirationDate].PUTPremium ? 'bg-rose-800' : ''}`}>
										<span className={`${optionChainTotals[selectedExpirationDate].PUTPremium > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].PUTPremium ? `$${optionChainTotals[selectedExpirationDate].PUTPremium.toLocaleString()}` : ''}</span>
										<span className={`${optionChainTotals[selectedExpirationDate].PUTPremium > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].PUTPremium ? `$${abbrInt(optionChainTotals[selectedExpirationDate].PUTPremium, false)}` : ''}</span>
									</span>
								: <></>}
								{contractView==='last' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex`}>
										&nbsp;
									</span>
								: <></>}
								{contractView==='projectedoichange' ?
									<span className={`p-1 items-center justify-center border border-b-0 font-medium uppercase tracking-wider w-14 sm:w-20 flex ${optionChainTotals[selectedExpirationDate].CALLProjectedChange < optionChainTotals[selectedExpirationDate].PUTProjectedChange ? 'bg-rose-800' : ''}`}>
										<span className={`${optionChainTotals[selectedExpirationDate].PUTProjectedChange > 99999 ? 'hidden' : 'hidden sm:flex'}`}>{optionChainTotals[selectedExpirationDate].PUTProjectedChange ? optionChainTotals[selectedExpirationDate].PUTProjectedChange.toLocaleString() : ''}</span>
										<span className={`${optionChainTotals[selectedExpirationDate].PUTProjectedChange > 99999 ? 'flex' : 'flex sm:hidden'}`}>{optionChainTotals[selectedExpirationDate].PUTProjectedChange ? abbrInt(optionChainTotals[selectedExpirationDate].PUTProjectedChange, false) : ''}</span>
									</span>
								: <></>}
							</div>
							:
							<></>
						}
					</div>
					:
					<></>
				}
			</div>
			:
			<></>
		}
		
		{/* {view === 'value' ?
			<div className="w-full h-full">
			
				{LoadingValue === true && <div className="h-100 flex items-center justify-center py-8 text-center text-gray-300"><LOADING color="text-green-500" /></div>}
				{(!LoadingValue && (!optionChains || !Object.keys(optionChains) || !Object.keys(optionChains).length)) &&
					<div className="h-full flex items-center justify-center py-8 text-center text-gray-300">no options</div>
				}

				{!LoadingValue && Object.keys(optionChains) && Object.keys(optionChains).length > 0 ?
					<div className="h-full w-full">
						<div className='flex flex-row w-full text-[7px] sm:text-xs'>
							<span className="p-1 items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-20 flex flex-grow">Expiration</span>
							<span className="p-1 flex items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32">Binary Calls</span>
							<span className="p-1 flex items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32">Hedging</span>
							<span className="p-1 flex items-center justify-center border border-gray-700 border-t-0 sm:font-medium sm:uppercase sm:tracking-wider w-14 sm:w-32">Binary Puts</span>
						</div>
						<div className="overflow-x-auto h-full max-h-[400px] xl:max-h-[700px] w-full">
							{loadOptionValueUI(optionExpirations)}
						</div>
					</div>
					:
					<></>
				}
			</div>
			:
			<></>
		} */}
		{view === 'stocknotional' ? <STOCK_NOTIONAL_CHART ticker={ticker} token={token} /> : <></>}
		{view === 'optionnotional' ? <OPTION_NOTIONAL_CHART ticker={ticker} token={token} /> : <></>}
		{view === 'orderbook' ? <ORDER_BOOK ticker={ticker} orderBook={orderBook} orderBookTime={orderBook && orderBook.CurrentTimestamp ? orderBook.CurrentTimestamp : null} /> : <></>}
		{view === 'value' ? <OPTION_PREMIUM_FLOW ticker={ticker} token={token} optionVolume={optionVolume} optionSnapshot={optionSnapshot} lastTrade={lastTrade} optionStrikeVolume={optionStrikeVolume} optionChains={optionChains} optionExpirations={optionExpirations} /> : <></>}
		{view === 'largest' ? <LARGEST_TRADES ticker={ticker} topTrades={topTrades} selectedLargestTradeType={selectedLargestTradeType}  notableTrades={notableTrades} topStockTradesByValue={topStockTradesByValue} topOptionTradeByValue={topOptionTradeByValue} topOptionBinaryTradeByValue={topOptionBinaryTradeByValue} topOptionTradeBySize={topOptionTradeBySize} /> : <></>}
		{view === 'trades' ? <TRADE_TAPE bid={bid} ask={ask} last={lastTrade} optionTrades={latestOptionTrades ? latestOptionTrades : []} stockTrades={latestStockTrades ? latestStockTrades : []} /> : <></>}
		{view === 'levels' ? <PRICE_LEVELS token={token} ticker={ticker} lastTrade={lastTrade} high={high} low={low} /> : <></>}
		{view === 'averages' ? <AVERAGES token={token} ticker={ticker} high={high} low={low} range={range} volume={volume} /> : <></>}
		{view === 'openinterest' ? <OPEN_INTEREST openInterestInfo={openInterestInfo} /> : <></>}
		{/* {view === 'liquidity' ? <LIQUIDITY ticker={ticker} /> : <></>} */}
			
		<OPTION_CONTRACT_MODAL open={ showTickerDetail } setOpen={() => { updateShowTickerDetail(false); updateTickerDetail(null); }} data={ tickerDetail } hideshare={true} />
	</div>
	);
};

export default React.memo(MAIN, dontRerender);