import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './styles/index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reduxstate from './state/store';

import Maintenance from './views/Maintenance';
import NotFound from './views/NotFound';
import Unavailable from './views/Unavailable';
import Home from './views/Home';

import Beta from './views/Beta';
import Vega from './views/Vega';

import TrendingV2 from './views/TrendingV2';
import Login from './views/Login';
import SignUp from './views/SignUp';
import AccountRecovery from './views/AccountRecovery';
import Validation from './views/Validation';
import Main from './views/Main';
import Account from './views/Account';
import Alerts from './views/Alerts';
import Unsubscribe from './views/Unsubscribe';
import Pricing from './views/Pricing';
import RankingsLargestStockTrades from './views/RankingsLargestStockTrades';
import RankingsLargestStockTradesNonAuction from './views/RankingsLargestStockTradesNonAuction';
// import Watchlist from './views/Watchlist';
// import About from './views/About';
// import Funds from './views/Funds';
// import Earnings from './views/Earnings';
// import ExchangeTradedFunds from './views/ExchangeTradedFunds';
// import SpecificExchangeTradedFunds from './views/SpecificExchangeTradedFunds';
// import HedgeFunds from './views/HedgeFunds';
// import IndexFunds from './views/IndexFunds';
// import MutualFunds from './views/MutualFunds';
// import PrivateFunds from './views/PrivateFunds';
// import PortfolioFunds from './views/PortfolioFunds';
// import TrustFunds from './views/TrustFunds';
// import CorporateFunds from './views/CorporateFunds';
// import InsiderFunds from './views/InsiderFunds';
// import AlternativeInvestmentFunds from './views/AlternativeInvestmentFunds';
import NotableTrades from './views/NotableTrades';
import ShareNotableTrade from './views/ShareNotableTrade';
import ShareNotableTradeInstant from './views/ShareNotableTradeInstant';
import TermsAndConditions from './views/TermsAndConditions';
// import Leveled from './views/Leveled';

// import Test from './views/Test';
// import Trending from './views/Trending';
// import OrderBook from './views/OrderBook';
// import OptionScales from './views/OptionScales';
// import TradeTrace from './views/TradeTrace';
// import ShortSaleRestrictionList from './views/ShortSaleRestrictionList';
// import Media from './views/Media';
// import Heimdall from './views/Heimdall';
// import Orion from './views/Orion';
// import Thor from './views/Thor';
// import RsiSignals from './views/RsiSignals';
// import Automation from './views/Automation';
// import System from './views/System';
// import ThresholdList from './views/ThresholdList';
import Robinhood24hrMarket from './views/Robinhood24hrMarket';
import FailToDelivers from './views/FailToDelivers';
// import Admin from './views/Admin';
// import Backtest from './views/Backtest';
// import TradeLog from './views/TradeLog';
// import Discrepancies from './views/Discrepancies';


import SITE_SKELETON from './components/SITE_SKELETON';
import { WebSocketProvider } from './components/WEBSOCKET_CONTEXT';

const {store, persistor} = reduxstate();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<Routes>
					{/* <Route path="*" element={<Maintenance />} /> */}
					{/* <Route exact path="/test" element={<Test />} /> */}

					<Route exact path="/rankings-highest-stock-trades" element={<WebSocketProvider><SITE_SKELETON><RankingsLargestStockTrades /></SITE_SKELETON></WebSocketProvider>} />
					
					<Route exact path="/" element={<WebSocketProvider><SITE_SKELETON fullScreen={true}><Home /></SITE_SKELETON></WebSocketProvider>} />
					<Route path="/t/:symbol" element={<WebSocketProvider><SITE_SKELETON fullScreen={true}><Beta /></SITE_SKELETON></WebSocketProvider>} />
					<Route path="/v/:symbol" element={<WebSocketProvider><SITE_SKELETON fullScreen={true}><Vega /></SITE_SKELETON></WebSocketProvider>} />

					<Route exact path="/main" element={<WebSocketProvider><SITE_SKELETON><TrendingV2 /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/rankings-largest-stock-trades" element={<WebSocketProvider><SITE_SKELETON><RankingsLargestStockTrades /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/rankings-largest-stock-trades-non-auction" element={<WebSocketProvider><SITE_SKELETON><RankingsLargestStockTradesNonAuction /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/login" element={<WebSocketProvider><SITE_SKELETON noChrome={true}><Login /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/recovery" element={<WebSocketProvider><SITE_SKELETON noChrome={true}><AccountRecovery /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/validation" element={<WebSocketProvider><SITE_SKELETON noChrome={true}><Validation /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/signup" element={<WebSocketProvider><SITE_SKELETON noChrome={true}><SignUp /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/alerts" element={<WebSocketProvider><SITE_SKELETON><Alerts /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/unsubscribe" element={<WebSocketProvider><SITE_SKELETON><Unsubscribe /></SITE_SKELETON></WebSocketProvider>} />
					<Route path="/_/:symbol" element={<WebSocketProvider><SITE_SKELETON><Main /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/pricing" element={<WebSocketProvider><SITE_SKELETON><Pricing /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/notabletrades" element={<WebSocketProvider><SITE_SKELETON><NotableTrades /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/account" element={<WebSocketProvider><SITE_SKELETON><Account /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/share_notabletrade" element={<ShareNotableTrade />} />
					<Route exact path="/share_notabletrade_instant" element={<ShareNotableTradeInstant />} />
					<Route exact path="/termsandconditions" element={<TermsAndConditions />} />					
					<Route path="/notfound" element={<WebSocketProvider><SITE_SKELETON><NotFound /></SITE_SKELETON></WebSocketProvider>} />
					<Route path="/unavailable" element={<WebSocketProvider><SITE_SKELETON><Unavailable /></SITE_SKELETON></WebSocketProvider>} />
					<Route path="*" element={<WebSocketProvider><SITE_SKELETON><NotFound /></SITE_SKELETON></WebSocketProvider>} />

					<Route exact path="/ftds" element={<WebSocketProvider><SITE_SKELETON><FailToDelivers /></SITE_SKELETON></WebSocketProvider>} />
					<Route exact path="/robinhood24hrmarket" element={<WebSocketProvider><SITE_SKELETON><Robinhood24hrMarket /></SITE_SKELETON></WebSocketProvider>} />
					{/* <Route exact path="/admin" element={<WebSocketProvider><SITE_SKELETON><Admin /></SITE_SKELETON></WebSocketProvider>} /> */}
					
					{/* <Route exact path="/orion" element={<Orion />} /> */}
					{/* <Route exact path="/about" element={<WebSocketProvider><SITE_SKELETON><About /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/watchlist" element={<WebSocketProvider><SITE_SKELETON><Watchlist /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/funds" element={<WebSocketProvider><SITE_SKELETON><Funds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/earnings" element={<WebSocketProvider><SITE_SKELETON><Earnings /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/exchange-traded-funds" element={<WebSocketProvider><SITE_SKELETON><ExchangeTradedFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route path="/exchange-traded-funds/:ticker" element={<WebSocketProvider><SITE_SKELETON><SpecificExchangeTradedFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/hedge-funds" element={<WebSocketProvider><SITE_SKELETON><HedgeFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/index-funds" element={<WebSocketProvider><SITE_SKELETON><IndexFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/mutual-funds" element={<WebSocketProvider><SITE_SKELETON><MutualFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/private-funds" element={<WebSocketProvider><SITE_SKELETON><PrivateFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/portfolio-funds" element={<WebSocketProvider><SITE_SKELETON><PortfolioFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/trust-funds" element={<WebSocketProvider><SITE_SKELETON><TrustFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/corporate-funds" element={<WebSocketProvider><SITE_SKELETON><CorporateFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/insider-funds" element={<WebSocketProvider><SITE_SKELETON><InsiderFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
					{/* <Route exact path="/alternative-investment-funds" element={<WebSocketProvider><SITE_SKELETON><AlternativeInvestmentFunds /></SITE_SKELETON></WebSocketProvider>} /> */}
				</Routes>
			</Router>
		</PersistGate>
	</Provider>
);