import epochConverterToEST from "../bin/epochConverterToEST";
import conditions from "../bin/stockTradeConditions";
import exchanges from "../bin/exchanges";
import abbrInt from "../bin/abbrInt";
import truncateToTwoDigits from "../bin/truncateToTwoDigits";

const compileTradeDetailForModal = (trade, optionContractMap) => {
  let tradeDetail = [];
  let d = epochConverterToEST(trade.time);
  const commaSeparatedFlags = (flags) => {
    let _flags = flags.map( flag => {
      if (flag=='57' || flag=='58' || flag=='59' || flag=='60' || flag=='62' || flag=='63' || flag=='64' || flag=='65' || flag=='66' || flag=='67' || flag=='68' || flag=='69' || flag=='70'){
        return `<span class="bg-red-500 px-1 text-white font-thin text-xs">${ conditions[flag] }</span>`;
      }
      if (flag=='35'){
        return `<span class="bg-blue-500 px-1 text-white font-thin text-xs">${ conditions[flag] }</span>`;
      }
      if (conditions[flag]){
        return `<span class="bg-slate-500 px-1 text-white font-thin text-xs">${ conditions[flag] }</span>`;
      }
      return `<span class="bg-gray-500 px-1 text-white font-thin text-xs">${ flag }</span>`;
    }).join(' ');
    // return flags.map( flag => `<span class="bg-slate-500 px-1 text-white font-thin text-xs">${ conditions[flag] }</span>` ).join(' ');
    return _flags;
  };
  if (trade.ticker || trade.name){
    tradeDetail.push(`<span class="text-amber-600">Ticker: ${ (trade.ticker).toUpperCase() } ${ trade.name ? `<span class="ml-1 text-xs italic">${trade.name}</span>` : `` }</span>`);
  }
  if (trade.side){
    tradeDetail.push(`<span class="text-indigo-700">Side:</span> ${ trade.side ? (trade.side).toUpperCase() :'-' }`);
  }
  if (trade.type && trade.type==='option'){
    // tradeDetail.push(`<span class="text-indigo-700">Total Contracts:</span> ${ trade.qyt ? (trade.qyt).toLocaleString() :'-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Total Contracts:</span> ${ trade.qyt ? abbrInt(trade.qyt, false, false) :'-' }`);
		// tradeDetail.push(`<span class="text-indigo-700">Price per Contract:</span> ${ trade.price && trade.price!=='NaN' ? '$' + abbrInt(trade.price, false) : '-' }`);
		tradeDetail.push(`<span class="text-indigo-700">Price per Contract:</span> ${ trade.price && trade.price!=='NaN' ? '$' + truncateToTwoDigits(trade.price) : '-' }`);
		tradeDetail.push(`<div class="flex flex-row w-full justify-between items-start">
			<span class="text-indigo-700">Trade Value:</span>
			<span class="text-gray-500">${ trade.value && trade.value!=='NaN' ? '$' + abbrInt(trade.value) :'-' }</span>
		</div>
		<div class="flex justify-evenly text-gray-500 text-xs mt-0">
			<span class="w-full text-left">${d.FullMonth} ${d.Date}, ${d.Year}</span>
			<span class="w-full text-center">${d.Day}</span>
			<span class="w-full text-right">${d.Hour}:${d.Minute}:${d.Second}.<span class="text-xxs">${d.Millisecond}</span></span>
		</div>`);
  }else{
    // tradeDetail.push(`<span class="text-indigo-700">Total Shares:</span> ${ trade.qyt && trade.qyt!=='NaN'? (trade.qyt).toLocaleString() :'-' }`);
    tradeDetail.push(`<div class="flex flex-row w-full justify-between items-start">
			<span class="text-indigo-700">Trade:</span>
			<span class="text-gray-500">${ trade.qyt && trade.qyt!=='NaN'? (trade.qyt).toLocaleString() :'-' } shares for ${ trade.price && trade.price!=='NaN' ? '$' + truncateToTwoDigits(trade.price) : '-' }/each</span>
		</div>
		<div class="flex flex-row w-full justify-between items-start">
			<span class="text-indigo-700">Trade Value:</span>
			<span class="text-gray-500">${ trade.value && trade.value!=='NaN' ? '$' + abbrInt(trade.value) :'-' }</span>
		</div>
		<div class="flex justify-evenly text-gray-500 text-xs mt-0">
			<span class="w-full text-left">${d.FullMonth} ${d.Date}, ${d.Year}</span>
			<span class="w-full text-center">${d.Day}</span>
			<span class="w-full text-right">${d.Hour}:${d.Minute}:${d.Second}.<span class="text-xxs">${d.Millisecond}</span></span>
		</div>`);
    // tradeDetail.push(`<span class="text-indigo-700">Total Shares:</span> ${ trade.qyt ? abbrInt(trade.qyt, false, false) :'-' }`);
		// tradeDetail.push(`<span class="text-indigo-700">Price per Share:</span> ${ trade.price && trade.price!=='NaN' ? '$' + abbrInt(trade.price, false) : '-' }`);
		// tradeDetail.push(`<span class="text-indigo-700">Price per Share:</span> ${ trade.price && trade.price!=='NaN' ? '$' + truncateToTwoDigits(trade.price) : '-' }`);
	}

	// tradeDetail.push(`<div class="flex justify-evenly text-gray-500 text-xs mt-0">
	// 	<span class="w-full text-left">${d.FullMonth} ${d.Date}, ${d.Year}</span>
	// 	<span class="w-full text-center">${d.Day}</span>
	// 	<span class="w-full text-right">${d.Hour}:${d.Minute}:${d.Second}.<span class="text-xxs">${d.Millisecond}</span> <span class="text-xs">[est]</span></span>
	// </div>`);
	
  // tradeDetail.push(`<span class="text-indigo-700">Total Price:</span> ${ trade.price && trade.price!=='NaN' ? '$' + (trade.price).toLocaleString() : '-' }`);
  // tradeDetail.push(`<span class="text-indigo-700">Trade Value:</span> ${ trade.value && trade.value!=='NaN' ? '$' + (Number(trade.value)).toLocaleString() : '-' }`);
	// tradeDetail.push(`<span class="text-indigo-700">Trade Value:</span> ${ trade.value && trade.value!=='NaN' ? '$' + abbrInt(trade.value) : '-' }`);
	// tradeDetail.push(`<div class="flex flex-row w-full justify-between items-start">
	// 	<span class="text-indigo-700">Trade Value:</span>
	// 	<span class="text-gray-500">${ trade.value && trade.value!=='NaN' ? '$' + abbrInt(trade.value) :'-' }</span>
	// </div>`);
	
	if ((trade.bidBeforeTrade && trade.midBeforeTrade && trade.askBeforeTrade) || (trade.bidAfterTrade && trade.midAfterTrade && trade.askAfterTrade)) {
		tradeDetail.push(``);
	}
	//check if beforeTrade quotes match afterTrade quotes to reduce redundancy
	if (trade.bidBeforeTrade && trade.midBeforeTrade && trade.askBeforeTrade && trade.bidAfterTrade && trade.midAfterTrade && trade.askAfterTrade && trade.bidBeforeTrade === trade.bidAfterTrade && trade.midBeforeTrade === trade.midAfterTrade && trade.askBeforeTrade === trade.askAfterTrade) {
		tradeDetail.push(`
			<div class="flex items-start w-full flex-wrap">
				<div class="flex flex-row w-full sm:flex-col sm:w-[100px] justify-between sm:justify-start items-start">
					<span class="text-indigo-700">Quote:</span>
					<span class="text-gray-500 text-xxs">${trade.beforeQuoteTime}</span>
				</div>
				<div class="flex flex-col flex-grow min-w-[250px]">
					<div class="flex justify-evenly">
						<span class="w-full text-rose-700 text-left">${trade.bidBeforeTrade && trade.bidBeforeTrade !== 'NaN' ? '$' + truncateToTwoDigits(trade.bidBeforeTrade) : '-'}</span>
						<span class="w-full text-sky-700 text-center">${trade.midBeforeTrade && trade.midBeforeTrade !== 'NaN' ? '$' + truncateToTwoDigits(trade.midBeforeTrade) : '-'}</span>
						<span class="w-full text-emerald-700 text-right">${trade.askBeforeTrade && trade.askBeforeTrade !== 'NaN' ? '$' + truncateToTwoDigits(trade.askBeforeTrade) : '-'}</span>
					</div>
					<div class="flex justify-evenly text-gray-500 text-xs mt-0">
						<span class="w-full text-left">bid</span>
						<span class="w-full text-center">mid</span>
						<span class="w-full text-right">ask</span>
					</div>
				</div>
			</div>
		`);
	} else {
		if (trade.bidBeforeTrade && trade.midBeforeTrade && trade.askBeforeTrade) {
			tradeDetail.push(`
				<div class="flex items-start w-full flex-wrap">
					<div class="flex flex-row w-full sm:flex-col sm:w-[100px] justify-between sm:justify-start items-start">
						<span class="text-indigo-700">Quote Before:</span>
						<span class="text-gray-500 text-xxs">${trade.beforeQuoteTime}</span>
					</div>
					<div class="flex flex-col flex-grow min-w-[250px]">
						<div class="flex justify-evenly">
							<span class="w-full text-rose-700 text-left">${trade.bidBeforeTrade && trade.bidBeforeTrade !== 'NaN' ? '$' + truncateToTwoDigits(trade.bidBeforeTrade) : '-'}</span>
							<span class="w-full text-sky-700 text-center">${trade.midBeforeTrade && trade.midBeforeTrade !== 'NaN' ? '$' + truncateToTwoDigits(trade.midBeforeTrade) : '-'}</span>
							<span class="w-full text-emerald-700 text-right">${trade.askBeforeTrade && trade.askBeforeTrade !== 'NaN' ? '$' + truncateToTwoDigits(trade.askBeforeTrade) : '-'}</span>
						</div>
						<div class="flex justify-evenly text-gray-500 text-xs mt-0">
							<span class="w-full text-left">bid</span>
							<span class="w-full text-center">mid</span>
							<span class="w-full text-right">ask</span>
						</div>
					</div>
				</div>
			`);
		}
		if (trade.bidAfterTrade && trade.midAfterTrade && trade.askAfterTrade) {
			tradeDetail.push(`
				<div class="flex items-start w-full flex-wrap">
					<div class="flex flex-row w-full sm:flex-col sm:w-[100px] justify-between sm:justify-start items-start">
						<span class="text-indigo-700">Quote After:</span>
						<span class="text-gray-500 text-xxs">${trade.afterQuoteTime}</span>
					</div>
					<div class="flex flex-col flex-grow min-w-[250px]">
						<div class="flex justify-evenly">
							<span class="w-full text-rose-700 text-left">${trade.bidAfterTrade && trade.bidAfterTrade !== 'NaN' ? '$' + truncateToTwoDigits(trade.bidAfterTrade) : '-'}</span>
							<span class="w-full text-sky-700 text-center">${trade.midAfterTrade && trade.midAfterTrade !== 'NaN' ? '$' + truncateToTwoDigits(trade.midAfterTrade) : '-'}</span>
							<span class="w-full text-emerald-700 text-right">${trade.askAfterTrade && trade.askAfterTrade !== 'NaN' ? '$' + truncateToTwoDigits(trade.askAfterTrade) : '-'}</span>
						</div>
						<div class="flex justify-evenly text-gray-500 text-xs mt-0">
							<span class="w-full text-left">bid</span>
							<span class="w-full text-center">mid</span>
							<span class="w-full text-right">ask</span>
						</div>
					</div>
				</div>
			`);
		}
	}
  
	tradeDetail.push(``);
  if (trade.type && trade.type==='option' && trade.symbol && optionContractMap){
    tradeDetail.push(`<span class="text-indigo-700">Strike:</span> ${ trade.symbol && optionContractMap && optionContractMap[trade.symbol] && optionContractMap[trade.symbol].strike ? '$' + optionContractMap[trade.symbol].strike : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Expiry:</span> ${ trade.symbol && optionContractMap && optionContractMap[trade.symbol] && optionContractMap[trade.symbol].expiry ? optionContractMap[trade.symbol].expiry : '-' }`);
    tradeDetail.push(``);
  }
  if (trade.type && trade.type==='option'){
    tradeDetail.push(`<span class="text-indigo-700">Magnitude:</span> ${ trade.magnitude && trade.magnitude!=='NaN' ? (trade.magnitude).toLocaleString() + '%' : '-' }`);
    if (optionContractMap && optionContractMap[trade.symbol] && optionContractMap[trade.symbol].type){
      if (optionContractMap[trade.symbol].type==='CALL'){
        tradeDetail.push(`<span class="text-indigo-700">Break Even:</span> $${ (optionContractMap[trade.symbol].strike + trade.price).toLocaleString() }`);
      }else if (optionContractMap[trade.symbol].type==='PUT'){
        tradeDetail.push(`<span class="text-indigo-700">Break Even:</span> $${ (optionContractMap[trade.symbol].strike + (trade.price * -1 )).toLocaleString() }`);
      }
    }
    tradeDetail.push(`<span class="text-indigo-700">Last:</span> ${ trade.last && trade.last!=='NaN' ? '$' + (trade.last).toLocaleString() + ' [' + ( ((trade.last/trade.price-1)*100).toFixed() ) + '%]' : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Highest:</span> ${ trade.highest && trade.highest!=='NaN' ? '$' + (trade.highest).toLocaleString() + ' [' + ( ((trade.highest/trade.price-1)*100).toFixed() ) + '%]['+ trade.highesttime +']' : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Lowest:</span> ${ trade.lowest && trade.lowest!=='NaN' ? '$' + (trade.lowest).toLocaleString() + ' [' + ( ((trade.lowest/trade.price-1)*100).toFixed() ) + '%]['+ trade.lowesttime +']' : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Average Value:</span> ${ trade.avgValue && trade.avgValue!=='NaN' ? '$' + (trade.avgValue).toLocaleString() : '-' }`);
    tradeDetail.push(`<span class="text-indigo-700">Average Contracts:</span> ${ trade.avgQyt && trade.avgQyt!=='NaN' ? (trade.avgQyt).toLocaleString() : '-' }`);
    tradeDetail.push(``);
  }
  // tradeDetail.push(`<span class="text-indigo-700">Time:</span> ${d.Hour}:${d.Minute}:${d.Second}.<span class="text-xxs">${d.Millisecond}</span> <span class="text-xs">[est]</span>`);
  // tradeDetail.push(`<span class="text-indigo-700">Date:</span> ${d.FullMonth} ${d.Date}, ${d.Year}`);
  // tradeDetail.push(`<span class="text-indigo-700">Day:</span> ${d.Day}`);
  // tradeDetail.push(``);
  if (trade.ex){
    tradeDetail.push(`<span class="text-indigo-700">Exchange:</span> ${ isNaN(trade.ex)===false ? exchanges[trade.ex] : trade.ex }`);
  }
  if (trade.flags){
    tradeDetail.push(`<span class="text-indigo-700">Trade Flags:</span> ${ trade.flags && trade.flags.length ? commaSeparatedFlags(trade.flags) : '-' }`);
  }
  tradeDetail = tradeDetail.join('<br/>');
  return tradeDetail;
 };

 export default compileTradeDetailForModal;