import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useStore } from 'react-redux';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import config from "../config.json";
import dontRerender from "../bin/dontRerender";
import decodeJwt from "../bin/decodeJwt";
import authorizedLevel from "../bin/authorizedLevel";
import Headers from "../bin/Headers";
import _logout from "../bin/logout";
import actions from '../state/actions';
import MODAL from "../components/MODAL";
import TRACKING from "../components/TRACKING";
import NOTABLES from "../components/NOTABLES";
import RANKINGS from "../components/RANKINGS";
import POSITIONS from "../components/POSITIONS";
import setMetaTags from '../bin/setMetaTags';

import { ChevronDoubleRightIcon, ChevronDownIcon, ChevronRightIcon, XMarkIcon, UserIcon } from "@heroicons/react/24/solid";

const Trending = () => {
	const store = useStore(),
		navigate = useNavigate(),
		token = useSelector(state => state.token),
		avatar = useSelector(state => state.avatar),
		[searchParams, setSearchParams] = useSearchParams(),
		[view, setView]= useState('rankings'),
		[levelChangedTo, setLevelChangedTo]= useState(null),
		[levelChangeDirection, setLevelChangeDirection]= useState(null),
		[showLevelChangeDialog, setShowLevelChangeDialog]= useState(false),
		[showPushEnableDialog, setShowPushEnableDialog]= useState(false),
		[showBroadcastDialog, setShowBroadcastDialog]= useState(false),
		[broadcastDialog, setBroadcastDialog]= useState(null),
		[subview, setSubview]= useState(''),
		[study, setStudy]= useState(''),
		[navExpansion, showNavExpansion]= useState(false),
		[subnavExpansion, showSubnavExpansion]= useState(false),
		intervalRef = useRef(null),
		logoutUser = _logout(),
		decodedToken = decodeJwt(token);

	const askForNotificationPermission = async () => {
		try {
			const permission = await Notification.requestPermission();
			if (permission !== 'granted') { return; }
			
			const registration = await navigator.serviceWorker.ready;
			let subscription = await registration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: urlBase64ToUint8Array(config.vapid_key)
			});
			const authKey = subscription.getKey ? btoa(String.fromCharCode.apply(null, new Uint8Array( subscription.getKey("auth") ))) : "";
      const p256dhKey = subscription.getKey ? btoa(String.fromCharCode.apply(null, new Uint8Array( subscription.getKey("p256dh") ))) : "";
      //build the payload
      let payload = {
        endpoint: subscription.endpoint,
        expirationTime: subscription.expirationTime,
        authKey,
        p256dhKey,
			};
			let data = await fetch(`${config.api}/notifications`, {method:"POST", headers:Headers(token), body:JSON.stringify(payload)}).then(d => d.json()).then(d => d.message && d.message==='ok' ? true : d);
			// if (!data) { alert(data); return; }
		} catch (err) {
			// setPushNotifications(false);
		}
	};

	function urlBase64ToUint8Array(base64String) {
		const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
		const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);
		for (let i = 0; i < rawData.length; ++i) { outputArray[i] = rawData.charCodeAt(i);}
		return outputArray;
	}

	async function isUserSubscribed() {
		if (!('PushManager' in window)) { return; } // Push isn't supported on this browser

		try {
			if ("serviceWorker" in navigator) {
				const registration = await navigator.serviceWorker.register("/service-worker.js");
				const subscription = await registration.pushManager.getSubscription();
				//check localstorage to see if the user has declined push notifications
				if (localStorage.getItem("promotedPushNotifications")) { return; }
				
				if (!subscription) {
					setShowPushEnableDialog(true);
					localStorage.setItem("promotedPushNotifications", "true");
				}
				
      }
		} catch (error) {
			console.error("Error during service worker registration:", error);
		}
	}

	const sendToNotables = () => {
		if (view === 'notables') { return '/notabletrades'; }
		return '/main?view=notables';
	};

	const checkLevel = async () => {
		if (!token) { return; }
		let data = await fetch(`${config.api}/levelcheck`, { method: "GET", headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result ? d.result : d);
		if (data && data.message && data.message==='invalid token') { return logoutUser(); }
		if (!data || !data.levelChanged) { return; }
		let _levelChangedTo = data.level;
		//check first character if (a-z) then capitalize
		if (_levelChangedTo && _levelChangedTo.length > 0 && _levelChangedTo.charCodeAt(0) >= 97 && _levelChangedTo.charCodeAt(0) <= 122) {
			_levelChangedTo = _levelChangedTo.charAt(0).toUpperCase() + _levelChangedTo.slice(1);
		}
		setLevelChangedTo(_levelChangedTo);
		setShowLevelChangeDialog(true);
		setLevelChangeDirection(data.changeDirection);
		let newToken = data.token;
		store.dispatch(actions.updateToken(newToken));
		if (data.level) {
			store.dispatch(actions.updateTieLevel(data.level));
		}
	};
	
	const readUnseenBroadcastMessages = async () => {
		if (!token) { return; }
		let data = await fetch(`${config.api}/messages?state=unseen&type=broadcast&limit=1&sort=Sent:-1`, { method: "GET", headers: Headers(token) }).then(d => d.json()).then(d => d.message && d.message === 'ok' && d.result && d.result[0] ? d.result[0] : null);
		if (!data || !data.Title) { return; }
		setShowBroadcastDialog(true);
		setBroadcastDialog({headline:data.Title, message:data.Body ? data.Body : '', url:data.Url ? data.Url : '',});
		//after 3 seconds mark as seen
		setTimeout(async () => {
			await fetch(`${config.api}/messages`, { method: "PUT", headers: Headers(token), body: JSON.stringify({ ids: [data._id] }) });
		}, 3000);
	};

	const start = async () => {
		await Promise.all([checkLevel(), readUnseenBroadcastMessages()]);
		setMetaTags({ title: 'Red Striped Tie' });
		let urlView = searchParams.get("view");
		let urlSubview = searchParams.get("subview");
		let urlStudy = searchParams.get("study");
		if (urlView) { setView(urlView); }

		if (urlView === 'tracking') {
			setStudy('');
			if (!urlSubview) { setSubview('tickers'); }
			else if (urlSubview === 'tickers' || urlSubview==='positivechange' || urlSubview==='negativechange') { setSubview(urlSubview); }
		}
		if (urlView === 'rankings') {
			if (!urlSubview) { setSubview('trade'); setStudy('stock'); }
			else if (urlSubview === 'capital' && !study) { setSubview(urlSubview); setStudy('stocknotional'); }
			else if (urlSubview === 'trade' && !study) { setSubview(urlSubview); setStudy('stock'); }
			else if (urlSubview === 'volume' && !study) { setSubview(urlSubview); setStudy('stockvolume'); }
			else if (urlSubview === 'change' && !study) { setSubview(urlSubview); setStudy('percentgain'); }
		} else { setSubview(urlSubview || subview); setStudy(urlStudy || study); }
		//default
		if (!urlView && !subview) {
			setView('rankings');
			setSubview('trade');
		}
	};
	useEffect(() => { start(); }, [searchParams]);

  return (
		<div className={`${!token ? 'hidden' : ''} py-4 text-white w-full`}>
			<div className="flex flex-col border-t border-b border-gray-700 mb-5">
				<div className="flex flex-row py-3">
					<div className="flex-grow flex flex-row items-center overflow-x-auto hide-horizontal-scrollbar space-x-2">
						
						<Link className='h-8 w-8 rounded-full block mx-2' to="/account">
							{avatar ? <img className="max-w-[50px] inline-block border border-gray-500 shadow h-8 w-8 rounded-b-2xl rounded-t-md" src={avatar} alt="avatar" /> : <UserIcon className="h-8 w-8 rounded-b-2xl rounded-t-md border border-gray-700 bg-gray-800" />}
						</Link>
	
						<Link to="/main?view=tracking" onClick={() => { setView('tracking'); setSubview(''); setStudy(''); showNavExpansion(false); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${view === 'tracking' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Tracking</Link>
						{/* <Link to="/main?view=trending" className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${view==='trending' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Trending</Link> */}
						<Link to={sendToNotables()} onClick={() => { setView('notables'); setSubview(''); setStudy(''); showNavExpansion(false); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${view==='notables' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Notables</Link>
						<Link to="/main?view=rankings" onClick={() => { setView('rankings'); setSubview(''); setStudy(''); showNavExpansion(false); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${view==='rankings' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Rankings</Link>
						{authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : null), 'diamond') ? <Link to="/main?view=positions" onClick={() => { setView('positions'); setSubview(''); setStudy(''); showNavExpansion(false); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${view==='positions' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Positions</Link> : <></>}
						{/* {authorizedLevel((decodedToken && decodedToken.level ? decodedToken.level : null), 'diamond') ? <Link to="/admin" className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-400`}>Admin</Link> : <></>} */}
					</div>
					<div className={`${(view==='tracking' || view==='notables') ? 'hidden' : 'flex'}`}>
						<button className={`px-4 py-2 text-white ${navExpansion ? 'hidden' : ''}`} onClick={() => { showNavExpansion(true); }}><ChevronDownIcon className="h-6 w-6" /></button>
						<button className={`px-4 py-2 text-red-400 ${navExpansion ? '' : 'hidden'}`} onClick={() => { showNavExpansion(false); showSubnavExpansion(false); }}><XMarkIcon className="h-6 w-6" /></button>
					</div>
				</div>
				<div className={`${navExpansion ? 'flex' : 'hidden'} py-3 bg-sky-950/20 w-full flex-row overflow-x-auto hide-horizontal-scrollbar space-x-2`}>
					<div className={`${view==='tracking' ? 'flex' : 'hidden'} flex-grow flex-row overflow-x-auto hide-horizontal-scrollbar space-x-2`}>
						{/* <div className="flex px-4 py-2 text-gray-300 "><ChevronRightIcon className="h-6 w-6" /></div> */}
						{/* <Link to={`/main?view=${view}&subview=tickers`} onClick={() => { setStudy(''); showNavExpansion(false); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'tickers' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Tickers</Link>
						<Link to={`/main?view=${view}&subview=trades`} onClick={() => { setStudy(''); showNavExpansion(false); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'trades' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Trades</Link>
						<Link to={`/main?view=${view}&subview=ties`} onClick={() => { setStudy(''); showNavExpansion(false); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'ties' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Ties</Link> */}

						<Link to={`/main?view=${view}&subview=tickers`} onClick={() => { setStudy(''); showNavExpansion(true); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'tickers' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Tickers</Link>
						{/* <Link to={`/main?view=${view}&subview=trades`} onClick={() => { setStudy(''); showNavExpansion(true); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'trades' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Trades</Link> */}
						{/* <Link to={`/main?view=${view}&subview=ties`} onClick={() => { setStudy(''); showNavExpansion(true); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'ties' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>My Ties</Link> */}
						{/* <Link to={`/main?view=${view}&subview=${subview === 'positivechange' ? 'negativechange' : (subview === 'negativechange' ? 'positivechange' : 'positivechange')}`} onClick={() => { setSubview(subview === 'positivechange' ? 'negativechange' : (subview === 'negativechange' ? 'positivechange' : 'positivechange')); setStudy(''); showNavExpansion(true); showSubnavExpansion(false); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'negativechange' ? 'text-gray-200 bg-rose-700/40' : (subview === 'positivechange' ? 'text-gray-200 bg-emerald-700/40' : 'text-gray-400')}`}>Change</Link> */}
					</div>
					<div className={`${view==='rankings' ? 'flex' : 'hidden'} flex-grow flex-row overflow-x-auto hide-horizontal-scrollbar space-x-2`}>
						{/* <div className="flex px-4 py-2 text-gray-300 "><ChevronRightIcon className="h-6 w-6" /></div> */}
						<Link to={`/main?view=${view}&subview=trade`} onClick={() => { setStudy(''); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'trade' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Trade</Link>
						<Link to={`/main?view=${view}&subview=capital`} onClick={() => { setStudy(''); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'capital' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Capital</Link>
						<Link to={`/main?view=${view}&subview=volume`} onClick={() => { setStudy(''); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'volume' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Volume</Link>
						<Link to={`/main?view=${view}&subview=change`} onClick={() => { setStudy(''); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${subview === 'change' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Change</Link>
					</div>
					<div className={`${view==='rankings' ? 'flex' : 'hidden'}`}>
						<button className={`px-4 py-2 text-white ${subnavExpansion ? 'hidden' : ''}`} onClick={()=>showSubnavExpansion(true)}><ChevronDownIcon className="h-6 w-6" /></button>
						<button className={`px-4 py-2 text-red-400 ${subnavExpansion ? '' : 'hidden'}`} onClick={()=>showSubnavExpansion(false)}><XMarkIcon className="h-6 w-6" /></button>
					</div>
				</div>
				<div className={`${subnavExpansion ? 'flex' : 'hidden'} py-3 bg-violet-950/20 w-full flex-row overflow-x-auto hide-horizontal-scrollbar space-x-2 px-2`}>
					<div className={`${subview==='trade' ? 'flex' : 'hidden'} flex-grow flex-row overflow-x-auto hide-horizontal-scrollbar space-x-2`}>
						{/* <div className="flex px-4 py-2 text-gray-300 "><ChevronDoubleRightIcon className="h-6 w-6" /></div> */}
						<Link to={`/main?view=${view}&subview=${subview}&study=stock`} onClick={() => { setSubview('trade'); setStudy('stock'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='stock' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Stock</Link>
						{/* <Link to={`/main?view=${view}&subview=${subview}&study=option`} onClick={() => { setSubview('trade'); setStudy('option'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='option' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Option</Link> */}
						<Link to={`/main?view=${view}&subview=${subview}&study=binaryoption`} onClick={() => { setSubview('trade'); setStudy('binaryoption'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='binaryoption' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Binary Option</Link>
					</div>
					<div className={`${subview==='capital' ? 'flex' : 'hidden'} flex-grow flex-row overflow-x-auto hide-horizontal-scrollbar space-x-2`}>
						{/* <div className="flex px-4 py-2 text-gray-300 "><ChevronDoubleRightIcon className="h-6 w-6" /></div> */}
						<Link to={`/main?view=${view}&subview=${subview}&study=stocknotional`} onClick={() => { setSubview('capital'); setStudy('stocknotional'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='stocknotional' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Stock Notional</Link>
						<Link to={`/main?view=${view}&subview=${subview}&study=optionpremium`} onClick={() => { setSubview('capital'); setStudy('optionpremium'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='optionpremium' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Option Premium</Link>
						<Link to={`/main?view=${view}&subview=${subview}&study=binaryoptionpremium`} onClick={() => { setSubview('capital'); setStudy('binaryoptionpremium'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='binaryoptionpremium' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Binary Option Premium</Link>
					</div>
					<div className={`${subview==='volume' ? 'flex' : 'hidden'} flex-grow flex-row overflow-x-auto hide-horizontal-scrollbar space-x-2`}>
						{/* <div className="flex px-4 py-2 text-gray-300 "><ChevronDoubleRightIcon className="h-6 w-6" /></div> */}
						<Link to={`/main?view=${view}&subview=${subview}&study=stockvolume`} onClick={() => { setSubview('volume'); setStudy('stockvolume'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='stockvolume' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Stock</Link>
						<Link to={`/main?view=${view}&subview=${subview}&study=optionvolume`} onClick={() => { setSubview('volume'); setStudy('optionvolume'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='optionvolume' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Option</Link>
						<Link to={`/main?view=${view}&subview=${subview}&study=oddlot`} onClick={() => { setSubview('volume'); setStudy('oddlot'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='oddlot' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Odd Lot</Link>
						<Link to={`/main?view=${view}&subview=${subview}&study=stockturnover`} onClick={() => { setSubview('volume'); setStudy('stockturnover'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='stockturnover' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Stock Turnover</Link>
						<Link to={`/main?view=${view}&subview=${subview}&study=optionturnover`} onClick={() => { setSubview('volume'); setStudy('optionturnover'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='optionturnover' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Option Turnover</Link>
					</div>
					<div className={`${subview==='change' ? 'flex' : 'hidden'} flex-grow flex-row overflow-x-auto hide-horizontal-scrollbar space-x-2`}>
						{/* <div className="flex px-4 py-2 text-gray-300 "><ChevronDoubleRightIcon className="h-6 w-6" /></div> */}
						<Link to={`/main?view=${view}&subview=${subview}&study=percentgain`} onClick={() => { setSubview('change'); setStudy('percentgain'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='percentgain' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Percent Gain</Link>
						<Link to={`/main?view=${view}&subview=${subview}&study=percentloss`} onClick={() => { setSubview('change'); setStudy('percentloss'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='percentloss' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Percent Loss</Link>
						<Link to={`/main?view=${view}&subview=${subview}&study=marketcapgain`} onClick={() => { setSubview('change'); setStudy('marketcapgain'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='marketcapgain' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Market Cap Gain</Link>
						<Link to={`/main?view=${view}&subview=${subview}&study=marketcaploss`} onClick={() => { setSubview('change'); setStudy('marketcaploss'); }} className={`whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${study==='marketcaploss' ? 'text-gray-200 bg-gray-50/5' : 'text-gray-400'}`}>Market Cap Loss</Link>
					</div>
				</div>
			</div>

			{view === 'tracking' ? <TRACKING view={view} subview={subview} /> : <></>}
			{view === 'notables' ? <NOTABLES /> : <></>}
			{view === 'rankings' ? <RANKINGS view={view} subview={subview} study={study} /> : <></>}
			{view === 'positions' ? <POSITIONS /> : <></>}
			{/* {view === 'orders' ? <ORDERS /> : <></>} */}

			<MODAL
				open={showPushEnableDialog}
				setOpen={setShowPushEnableDialog}
				type="Info"
				headline="Enable Push Notifications"
				information='Get real-time alerts on Notable Option Trades.'
				action_confirm={true}
				confirmText='Enable'
				notifyConfirm={() => { askForNotificationPermission() }} />
			<MODAL
				open={showLevelChangeDialog}
				setOpen={setShowLevelChangeDialog}
				type="Info"
				headline={`Level ${levelChangeDirection && levelChangeDirection === 'UPGRADE' ? `Upgraded!` : `Changed`}`}
				html_information={true}
				information={`Your level has been ${levelChangeDirection && levelChangeDirection==='UPGRADE' ? `upgraded to ${levelChangedTo}!<br/>Thanks for supporting development on RedStripedTie!` : `changed to Red.<br/>Thanks for continuing to be apart of RedStripedTie.`}`} />
			<MODAL
				open={showBroadcastDialog}
				setOpen={setShowBroadcastDialog}
				type="Message"
				headline={broadcastDialog && broadcastDialog.headline ? broadcastDialog.headline : 'Notice'}
				html_information={true}
				information={broadcastDialog && broadcastDialog.message ? broadcastDialog.message : ''} />
    </div>
  );
}
export default React.memo(Trending, dontRerender);